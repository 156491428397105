import { NotificationType } from "@/api/requests/notifications/GetAllNotificationsRequest";

export class Notification {
	Record = 0;
	NotificationId = "";
	GenericId = "";
	Type: NotificationType = NotificationType.Tarefa;
	Description = "";
	Time = "";
	HasReaded = false;
	Title = "";
	Icon = "";
	Link = "";
	IsTask = false;
}

export const notificationTypes = {
	0: {
		title: "Notificação",
		icon: "ri-file-unknow-line",
		path: "",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	1: {
		title: "Aniversário",
		icon: "ri-cake-2-line",
		path: "",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	2: {
		title: "Atividade",
		icon: "ri-checkbox-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: true,
	},
	3: {
		title: "Equipe",
		icon: "ri-group-2-line",
		path: "equipes/",
		hasDirectLink: true,
		isAdmin: false,
		isTask: false,
	},
	4: {
		title: "Entrega",
		icon: "ri-calendar-event-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: true,
	},
	5: {
		title: "Férias",
		icon: "ri-empathize-line",
		path: "minha-conta",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	6: {
		title: "Feriado",
		icon: "ri-goblet-line",
		path: "",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	7: {
		title: "Justificativa de atraso",
		icon: "ri-error-warning-line",
		path: "meus-registros",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	8: {
		title: "Novo ajuste",
		icon: "ri-contacts-book-2-line",
		path: "ajustes/avaliar/",
		hasDirectLink: true,
		isAdmin: true,
		isTask: false,
	},
	9: {
		title: "Reunião",
		icon: "ri-slideshow-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: true,
	},
	10: {
		title: "Kanban",
		icon: "ri-table-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: true,
	},
	11: {
		title: "Ajuste de registro",
		icon: "ri-contacts-book-2-line",
		path: "meus-registros",
		hasDirectLink: true,
		isAdmin: false,
		isTask: false,
	},
	12: {
		title: "Abono de falta",
		icon: "ri-file-paper-2-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: false,
	},
	13: {
		title: "Justificativa de Fora da jornada",
		icon: "ri-quill-pen-line",
		path: "minhas-justificativas/",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	14: {
		title: "Justificativa de Ausência",
		icon: "ri-quill-pen-line",
		path: "minhas-justificativas/",
		hasDirectLink: false,
		isAdmin: false,
		isTask: false,
	},
	15: {
		title: "Justificativa de Ausência",
		icon: "ri-quill-pen-fill",
		path: "justificativas/",
		hasDirectLink: false,
		isAdmin: true,
		isTask: false,
	},
	16: {
		title: "Abono de falta",
		icon: "ri-file-paper-2-line",
		path: "abonos/",
		hasDirectLink: true,
		isAdmin: true,
		isTask: false,
	},
	17: {
		title: "Justificativa de Fora da Jornada",
		icon: "ri-quill-pen-fill",
		path: "justificativas/",
		hasDirectLink: false,
		isAdmin: true,
		isTask: false,
	},
	18: {
		title: "Comentário",
		icon: "ri-chat-new-line",
		path: "",
		hasDirectLink: true,
		isAdmin: false,
		isTask: true,
	},
	19: {
		title: "Novo registro",
		icon: "ri-contacts-book-2-line",
		path: "registros/avaliar/",
		hasDirectLink: true,
		isAdmin: true,
		isTask: false,
	},
	20: {
		title: "Novo registro",
		icon: "ri-contacts-book-2-line",
		path: "meus-registros/",
		hasDirectLink: true,
		isAdmin: false,
		isTask: false,
	},
};