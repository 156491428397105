import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetAllNotificationsRequest {
	Record = 0;

	constructor(record: number) {
		this.Record = record;
	}

	async execute(): Promise<DefaultListResponse<NotificationInfo>> {
		const response = await Api.get("/notifications/all-notifications", {
			params: this,
		});
		return response.data;
	}
}

interface NotificationInfo {
	Record: number,
	NotificationId: string,
	GenericId: string,
	Type: NotificationType,
	Description: string,
	IsReaded: boolean,
	Time: string,
}

export enum NotificationType {
	Aniversario = 1,
	Tarefa,
	Equipe,
	Entrega,
	Ferias,
	Feriado,
	JustificativaAtraso,
	NovoAjuste,
	Reuniao,
	LimiteKanban,
	StatusAjuste,
	Abono,
	JustificativaForaJornada,
	JustificativaAusencia,
	JustificativaAusenciaFuturaAdmin,
	AbonoAdmin,
	JustificativaHoraExtraAdmin,
	LogCommentary,
	UserRecordSolicitation
}