export class UserInfo {
	Id = "";
	Name = "";
	ImageUrl = "";
	IsDeleted = false;
}

export class UserInfoMeetings extends UserInfo {
	BusyTimes: BusyTimes[] = []
}

export class BusyTimes {
	Type = BusyTimesTypes.MEETING
	Begin = "";
	End = "";
}

export enum BusyTimesTypes {
	MEETING = 1,
	EXTERNAL,
	MISSWORK,
	ABSENCE,	
	VACATION,
}