
export interface IUpdateState {
	newsArray: updates[];
	oldVersion: string | null;
}

export const state = (): IUpdateState => {
	return {
		newsArray: newsArray,
		oldVersion: localStorage.getItem('version'),
	};
};

export interface updates {
	version: string,
	news: news[]
}
interface news {
	title: string,
	text: string
}

const newsArray = [
	{
		version: "0.8.1",
		news: [
			{
				title: "🧾 Justificativas",
				text: "A tela de justificativas está de cara nova! Além de poder adicionar um período externo (que antes era possível somente pela tela Geral), a nova visualização em formato de abas permite que você veja as informações de forma mais organizada e intuitiva!"
			},
			{
				title: "📅 Reuniões",
				text: "Acabaram seus problemas de conflito de agenda! Agora a visualização das próximas reuniões na tela Geral pode ser filtrada por uma ou mais salas, além das novas indicações visuais de possíveis usuários convocados que já possuem outro compromisso no mesmo horário ao criar ou visualizar uma reunião existente!"
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Aviso ao sair da tela aparecia ao solicitar um ajuste de registro.<br>
							- Adição do loading ao carregar as tarefas buscadas e inclusão da limpeza no campo de busca.<br>
							- Ícones da aba lateral não acompanhavam a cor do item selecionado.<br>
							- Reuniões privadas apareciam para não membros na tela Geral.<br>
							- Botões do tipo dropdown não fechavam ao clicar fora.<br>
							- Reuniões não atualizavam ao serem editadas no Geral, sem que a página fosse atualizada.<br>
							`
			},
		],
	},
	{
		version: "0.8",
		news: [
			{
				title: "🔍 Busca por atividades",
				text: "Quer acessar uma atividade mas não consegue achá-la? Basta procurar pelo seu nome na barra de pesquisa e ela será encontrada!"
			},
			{
				title: "📅 Próximas reuniões",
				text: "Na tela inicial, agora você poderá filtrar as reuniões para que veja somente as quais você está convidado!"
			},
			{
				title: "📄 Paginação",
				text: "Acessar a primeira ou a última página de uma grande lista de informações ficou muito mais fácil!"
			},
			{
				title: "🔝 Melhorias ao solicitar registros",
				text: "Além criar uma solicitação de registros das horas em período externo, agora você não precisa digitar as datas e horários cada vez que for adicionar um novo período. Os campos serão repetidos e você poderá alterar conforme houver necessidade!"
			},
			{
				title: "👍 Confirmação ao sair da página",
				text: "Em telas onde há campos a serem preenchidos (como nas solicitações de registros), agora você não corre mais o risco de sair da página por engano e perder todas as informações digitadas."
			},
			{
				title: "⌛ Banco de horas",
				text: `Agora o seu banco de horas não irá mais começar com o dia atual negativo, ele irá se ajustar de acordo com o período de sua jornada!<br>
				O banco de horas não será calculado desde o Big Bang!<br>`
			},
			{
				title: "🔝 Melhorias na Agenda",
				text: "Além de agora também contarem com a visualização em modo compacto, as atividades no modo Agenda estão de cara nova!"
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Anexos de solicitações de abonos de falta criadas pela "Minha conta" agora são enviados corretamente para o banco de dados.<br>
							- Tela do banco de horas mostra como "Hoje" somente o dia atual e não todos os dias do mês correspondentes àquela data.<br>
							- Melhorias no funcionamento geral das paginações<br>
							- Título da página na tela de atividades não atualizava da forma correta ao visualizar uma atividade e fechá-la em seguida.<br>
							- Mensagem no histórico de alterações para confirmação e desconfirmação em reuniões vem corretamente.<br>
							- Não era possível confirmar/desconfirmar a presença em uma reunião, se a mesma era uma atividade antes. <br>
							- Data inválida ao criar uma justificativa de ausência futura.<br>
							`
			},
		],
	},
	{	
		version: "0.7",
		news: [
			{
				title: "⏱️ Solicitação de registros",
				text: "Cansado de criar os registros de viagens e solicitar ajustes um por um? Agora você pode criar uma solicitação de registros das horas em período externo em lote!"
			},
			{
				title: "☑️ Atividades e reuniões",
				text: "Decidiu trocar uma atividade para uma reunião ou vice-versa? Agora você pode apenas editá-la ao invés de ter que criar uma nova atividade!"
			},
			{
				title: "🔴 Aviso de novidades",
				text: "Após cada atualização no Owntime, você será notificado na tela inicial e poderá conferir as novidades em primeira mão!"
			},
			{
				title: "📄 Logs das atividades",
				text: "O histórico de alterações das atividades são trazidos de acordo com sua necessidade, sem que sejam carregados todos ao mesmo tempo!"
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- O total de equipes e projetos mostra a quantidade correta de cada um na janela de filtro das atividades. <br>
							- Na visão de tabelas, as atividades globais que apareciam como privadas não aparecem mais desta maneira.<br>
							- Ao editar uma tarefa, adicionar um checklist e cancelar a edição, o checklist que permanecia na atividade não aparecerá mais. <br>
							- Usuários que possuem jornada de horas não terão mais período fora de jornada para justificar. <br>
							`
			},
		],
	},
	{
		version: "0.6.1",
		news: [
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Agora é possível criar uma justificativa prévia de fora de jornada em um feriado. <br>
							- Bug corrigido ao aprovar um ajuste de registro. `
			},
		],
	},
	{
		version: "0.6",
		news: [
			{
				title: "🧠 Sprints!",
				text: "Uma nova maneira de organização de atividades para projetos e equipes! Crie épicos e gerencie o andamento de atividades em um determinado período!",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Notificações inválidas não bloquearão mais as notificações anteriores. <br>
							- Gráficos agora responderão melhor à troca de tema claro/escuro. <br>
							- Botões em caixas de diálogo agora serão mais responsivos. `
			},
		],
	},
	{
		version: "0.5.4",
		news: [
			{
				title: "🕑 Justificativas prévias de Período Fora da Jornada",
				text: "Da mesma forma que as Justificativas prévias de ausência, é possível justificar um período para informar os colegas. Elas serão visíveis nas telas Geral e Justificativas, além do calendário do Dashboad - Gestão visual.",
			},
			{
				title: "📊 Mais opções no Dashboard",
				text: "No filtro, você agora poderá escolher resultados \"Sem equipe\" e \"Sem projeto\".",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Não é mais possível acessar atividades arquivadas pelo link delas. <br>
							- O seletor de visualização de atividades agora ocupa toda a largura da tela na versão mobile. <br>
							- Não haverá mais rolagem dentro das atividades. 
							`
			},
		],
	},
	{
		version: "0.5.3.1",
		news: [
			{
				title: "Melhorias na tela de atividades",
				text: "Uma lógica melhor de seleção de visualização foi adicionada. Alguns ícones foram atualizados. O nome da atividade agora ficará escrito no título da página (na aba!)",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- O filtro padrão de atividades agora carregará corretamente somente as atividades do usuário logado. <br>
							- A largura do nome da reunião não extrapolará mais alguns limites do card de próximas reuniões (Geral). <br>
							- A situação que bloqueava o uso do sistema após fechar um modal foi corrigida. <br> 
							- A paginação das semanas não influenciará mais a paginação do banco de horas. <br>
							- A paginação das revisões agora será exibida corretamente. <br>
							- As colunas e tabelas ocultas nas atividades se manterão ocultas ao recarregar a página. <br>
							- Não é mais possível deixar uma reunião sem membros convidados. <br>
							- As reuniões arquivadas não aparecerão mais no card de próximas reuniões (Geral). 
							`
			},
		],
	},
	{
		version: "0.5.3",
		news: [
			{
				title: "👨‍👩‍👧‍👦 Adicionar todos os usuários à atividade",
				text: "Precisa de todo mundo na atividade? Criamos um botão para isso! Ele também aparecerá na edição e criação de equipes, para dar aquela facilitada.",
			},
			{
				title: "⬅️ Visualizar anos anteriores no Banco de Horas",
				text: "Os usuários não estavam conseguindo ver o banco de horas de 2023. Colocamos botões para facilitar visualizar anos anteriores e também adicionamos uma caixa de diálogo com as Revisões do banco de horas!",
			},
			{
				title: "☑️ Atalho de nova atividade junto do atalho do Play",
				text: "Novamente, para facilitar, um botão de 'Nova atividade' foi adicionado ao atalho do Play (Entrada e Saída).",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- A atividade não ficará mais modificada se o usuário cancelou sua edição. <br>
							- A lista de atividades voltou a ser carregada após criar ou editar uma atividade. <br>
							- Atividades e reuniões arquivadas não lançarão mais notificações. <br>
							- Mudamos o ícone das Atividades. Um monitor não fazia muito sentido.<br>
							- Corrigido um problema que reconhecia de forma errada o momento de retorno das férias.<br>
							- Corrigido alguns botões extrapolando os limites de seus contêineres. <br>
							- Algumas notificações de feriado foram corrigidas para serem entregues no momento certo. <br>
							- O download do banco de horas pelo admin agora trás corretamente o ano selecionado. <br>
							`
			},
		],
	},
	{
		version: "0.5.2",
		news: [
			{
				title: "❣️ Exibição das Férias na lista de ausências",
				text: "Agora é possível visualizar o período de férias juntamente com as Justificativas de ausência futura e Período externo.",
			},
			{
				title: "📋 Próximas reuniões",
				text: "Agora são exibidas na tela inicial as próximas reuniões cadastradas! Para isso, basta selecionar a sala desejada e conferir os horários, assunto e convocados para aquela reunião.",
			},
			{
				title: "⏱️ Horários disponíveis",
				text: "Ao criar ou editar uma reunião, é possível visualizar os horários disponíveis no dia em que a reunião está marcada.",
			},
			{
				title: "☑️ Atividades concluídas",
				text: "Ao visualizar os projetos em andamento, agora a barra de progresso mostra numericamente quantas atividades foram concluídas em relação ao total de atividades do projeto.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Ao adicionar uma sala virtual ao criar uma reunião, o link ocupava um espaço maior do que o dedicado à ele.<br>
							- Correção do erro que não permitia criar ou editar uma Tag ao criar uma atividade.<br>
							- Melhorias visuais nas Configurações e página de exibição de Feriados.<br>
							- Melhorias no filtro existente na página de Registros (Admin).<br>
							- Correção ao acessar links das atividades na barra de notificações.<br>
							- Tabela de horas em projeto ao visualizar a equipe estava extrapolando o limite do card onde ela está inserida.<br>
							- A paginação das Justificativas de ausência na parte do Admin não estava aparecendo. <br>
							- Ao acessar o link que mostra a Semana no Banco de Horas (Admin) as horas (negativas e positivas) não estavam coloridas corretamente na versão dark.<br>
							- Tabelas de visualizar atividades no Projeto e de registro de horas em atividades no Banco de Horas (Admin) não mostrava áreas clicáveis ao passar o mouse.<br>
							- Ao arquivar uma atividade, a animação de carregamento continuava ocorrendo até que a página fosse atualizada. <br>
							`
			},
		],
	},
	{
		version: "0.5.1",
		news: [
			{
				title: "🖥️ Atividades em qualquer tela",
				text: "É possível visualizar a atividade em qualquer tela, sem ser direcionado para a página de atividades.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Corrigido bug em que certos textos estavam transbordando para fora do espaço dedicado à eles. <br>
							- Bug visual onde algumas telas que possuem foto de perfil do usuário estavam sem espaçamento entre o conteúdo na tela. <br>
							- A animação de carregamento na tela de banco de horas de admin estava encerrando antes de todo o conteúdo ser realmente carregado. <br>
							- Não era possível criar ou editar atividades utilizando a ferramenta "markdown". <br>
							- Ao clicar para adicionar uma etiqueta à uma atividade na tela de criação, o modal de criação fechava e era necessário atualizar a página. <br>
							- Campos de data ao selecionar um filtro na tela de Dashboard estavam um embaixo do outro, ao invés de lado a lado. <br>
							- Ao criar uma atividade, a animação de carregamento continuava ocorrendo até que a página fosse atualizada.`
			},
		],
	},
	{
		version: "0.5",
		news: [
			{
				title: "🏷️ Etiquetas nas atividades",
				text: "Crie etiquetas para melhorar a organização de atividades! Etiquetas podem ser cadastradas globalmente ou em equipes.",
			},
			{
				title: "📋 Cadastro e seleção de salas de reunião",
				text: "Salas de reunião agora podem ser cadastradas e filtradas. Uma reunião não pode ser marcada no mesmo horário que outra na mesma sala!",
			},
			{
				title: "🛫 Cadastro de período externo",
				text: "Informe aos seus colegas se você estará trabalhando fora do escritório durante determinado período.",
			},
			{
				title: "❣️ Cadastro de Férias em lote",
				text: "Acelere processos de cadastro de férias ao selecionar vários usuários ao mesmo tempo!",
			},
			{
				title: "🔔 Configuração de notificações",
				text: "Agora é possível configurar quais notificações você receberá no Owntime!",
			},
			{
				title: "️🔝 Melhorias gerais",
				text: "O gráfico e a tabela na tela 'Geral' agora mostram o número do dia da semana. A tela 'Visualizar Equipe' teve um pequeno ajuste visual. As atividades (próximas e últimas) na tela 'Geral' agora são exibidas exatamente como na tela 'Atividades'. Um histórico de registro de horas em atividades foi adicionado à tela 'Banco de Horas (admin)'. A performance no carregamento das listas de atividades foi melhorada.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Realizar uma entrada em um feriado não irá mais mostrar um atraso a ser justificado. <br>
							- É novamente possível alterar a quantidade de atividades carregadas na tabela na tela 'Visualizar Projeto'. <br>
							- O período de uma justificativa de ausência futura agora aparece corretamente para usuários Administradores. <br>
							- O atalho de Entrada e Saída não ficará mais escondido por baixo das Paginações. <br>
							- Ao abrir uma tela que contenha uma tabela muito grande não acarretará mais em lentidão. <br>`,
			},
		],
	},
	{
		version: "0.4.1",
		news: [
			{
				title: "✒️ Descrições com Markdown",
				text: "Agora é possível editar descrições de atividades e projetos utilizando o padrão Markdown. Negrito, itálico, títulos, links e muito mais!",
			},
			{
				title: "⏱️ Visualização de esforço nas atividades do Kanban",
				text: "Ao usar o modo Completo do Kanban, será possível visualizar o esforço configurado nelas.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- A data de uma ausência futura mostrará a data final, além da data de início. <br>
							- Ao instalar o OwnTime como aplicativo, a barra superior não aparecerá mais branca.`,
			},
		],
	},
	{
		version: "0.4",
		news: [
			{
				title: "⏱️ Esforço em atividades",
				text: "Incluímos um campo de Esforço nas atividades. Nele você pode especificar o tempo esperado de dedicação naquela tarefa.",
			},
			{
				title: "📈 Gestão visual",
				text: "O Dashboard do administrador agora conta com a opção Gestão visual. Nela é possível, por enquanto, visualizar períodos de ausência de colaboradores (como faltas e férias).",
			},
			{
				title: "🏦 Melhorias no Banco de Horas (Admin)",
				text: "Agora é possível visualizar a tabela com ou sem os usuários excluídos do sistema.",
			},
			{
				title: "📓 Melhorias Meus registros e Registros (Admin)",
				text: "Adicionamos um histórico de ajustes solicitados (aceitos, recusados, pendentes ou cancelados) com informações interessantes.",
			},
			{
				title: "🪶 Tela Justificativas",
				text: "Uma tela integrada para você acompanhar suas justificativas de Períodos fora de jornada e Ausências.",
			},
			{
				title: "🙂 Imagens de usuários adicionados em muito mais lugares",
				text: "Sabemos que ler nomes é cansativo demais, por isso, decidimos facilitar e adicionar as imagens desses usuários na maioria das listagens.",
			},
			{
				title: "📁 Atividades concluídas de projetos",
				text: "Adeus espaço vazio na lista de projetos. Olá barra de progresso de atividades concluídas!",
			},
			{
				title: "↔️ Barra lateral",
				text: "Você pode reduzir a largura da barra lateral com um único clique. Mais espaço para suas atividades e tabelas!",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Registros de entrada atrasados no segundo período agora serão reconhecidos como atraso. <br>
							- A opção de justificar atraso não vai mais sumir após algum tempo. <br>
							- O filtro de Atividades/Reuniões/Ambos agora salvará corretamente.`,
			},
		],
	},
	{
		version: "0.3.6",
		news: [
			{
				title: "👁️ Ocultar etapas de atividades",
				text: "Adicionamos a opção para ocultar etapas das atividades (colunas no Kanban e tabelas)!",
			},
			{
				title: "🔽 Filtros de atividades otimizados",
				text: "Ícones, contagem de itens, imagens de usuários e mais algumas coisinhas.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- A barra de notificações não ficará mais sobreposta por atividades enquanto você arrasta uma. <br>
							- Ao limpar os filtros de atividades, não serão mais preenchichas as datas de início e entrega. <br>
							- Algumas requisições não mostrarão mais notificações de erro ao reacessar o OwnTime do segundo plano pelo smartphone. <br>
							- Atividades voltarão a carregar após sair do modo Agenda.`,
			},
		],
	},
	{
		version: "0.3.5",
		news: [
			{
				title: "📄 Histórico de alterações de atividades",
				text: "Quem mudou a data de entrega? Quem removeu o fulano? Esperamos responder essas perguntas com o novo Histórico de alterações de atividades (e reuniões). Se você for um membro da atividade ou um administrador, você poderá adicionar um comentário em um item do histórico, seja para deixar uma observação ou para questionar alguma alteração. Também é possível visualizar as informações de criação da atividade/reunião no histórico.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Os gráficos de donut agora carregarão corretamente as informações após fazer um filtro. <br>
							- Os botões de Confirmar e Desconfirmar presença em reunião foram restaurados (a gente não sabe como eles sumiram). <br>
							- Porcentagens estranhas como "59.000000001%" não aparecerão mais na tela Geral. <br>
							- Ao tentar selecionar um texto no checklist você não irá mais arrastá-lo. <br>
							- Ao apagar um registro de horas de uma atividade, a lista não conterá mais aquele registro. <br>
							[+] Mais correções! <br>
							- Os nomes dos usuários voltaram a aparecer ao criar um abono. <br>
							- As datas de início e de entrega estarão corretamente exibidas no histórico das atividades. <br>
							- A data de nascimento não é mais obrigatória ao editar a Minha Conta. <br>
							- Corrigido um probleminha que impedia criar uma atividade com descrição maior que 256 caracteres.`,
			},
		],
	},
	{
		version: "0.3.4",
		news: [
			{
				title: "🖥️ Melhorias nas Atividades",
				text: "Mais espaço para as informações, mais ícones ilustrativos e link direto para projetos e equipes. A tela de registro de horas foi simplificada. Ao criar uma atividade, você será automaticamente inserido. Criado filtro para visualizar somente atividades ou somente reuniões.",
			},
			{
				title: "✅ Checklists",
				text: "Agora é possível marcar e desmarcar um checklist sem editar a atividade. Itens do checklist podem ser arrastados para reordenação (na tela de edição).",
			},
			{
				title: "📈 Dashboard",
				text: "Os administradores receberam acesso ao Dashboard, que informa dados úteis para melhor gerenciamento das atividades. Em breve, uma versão será liberada para todos os usuários.",
			},
			{
				title: "📄📄 Duplicação de atividades",
				text: "Ao duplicar a atividade, você poderá editar ela diretamente, ao invés de criá-la para depois acessar a edição.",
			},
			{
				title: "⏳ Horas lançadas em atividades",
				text: "Um resumo das horas lançadas em atividades está disponível na tela Geral (a porcentagem é calculada pelas horas lançadas em tarefas sobre as horas registradas no dia).",
			},
			{
				title: "🗃️ Atividades arquivadas",
				text: "O administrador agora consegue acessar a lista de atividades arquivadas e desarquiva-la, se for necessário. Além disso, mudamos o nome de \"Excluir\" para \"Arquivar\". Melhor, não?",
			},
			{
				title: "📅 Melhorias na Agenda",
				text: "As atividades agora estão visíveis na agenda, desde que elas possuam data de início e entrega.",
			},
			{
				title: "🔴 Notificações",
				text: "Você agora saberá quantas notificações não lidas possui. Além disto, as notificações não lidas sempre estarão acima das lidas.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- (Admin) O banco de horas semanal agora está sendo contabilizado corretamente. <br>
							- O seletor de membros da atividade não voltará ao estado original após selecionar um usuário. <br>
							- O modal de criação/edição da atividade não fechará mais ao fechar fora dele. <br>
							- Vários erros relacionados aos abonos foram corrigidos. <br>
							- Ao ver as horas lançadas num projeto, agora as atividades concluídas serão calculadas corretamente. <br>
							- Ao clicar em uma notificação de atividade ou reunião você não cairá mais na tela de "Não encontrado". <br>
							- A animação de carregamento irá parar corretamente após editar uma atividade.`,
			},
		],
	},
	{
		version: "0.3.3",
		news: [
			{
				title: "🖥️ Melhorias nas Atividades",
				text: "Somatório de pontos, tarefas e atrasadas, atividades públicas e privadas, somatório das horas lançadas por usuários, etc...",
			},
			{
				title: "▶️ Atalho do Play",
				text: "É possível registrar a entrada e saída a partir de qualquer tela (no desktop) utilizando o atalho do play (verifique o canto inferior direito de sua tela).",
			},
			{
				title: "🧑‍💼 Ferramentas de gestão",
				text: "Os projetos agora possuem Anexos, Visualização de atividades, Subprojetos e Horas trabalhadas (separadas por equipes e usuários). Ao acessar uma equipe, também é possível visualizar os projetos que foram trabalhados por ela. ",
			},
			{
				title: "⏳ Horas lançadas em atividades",
				text: "Você vai conseguir ver todas as horas lançadas em atividades acessando a página do Banco de Horas.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Solicitar/aprovar/reprovar/cancelar um abono agora notifica corretamente os usuários relacionados. <br>
							- Os ícones dos anexos dos abonos agora são exibidos corretamente em todos os formatos de arquivos. <br>
							- O sistema não trancará mais o usuário caso apresente erro ao cadastrar um período de férias. <br>
							- As tarefas próximas e últimas da tela inicial agora apresentarão corretamente as datas. <br>
							- Ao visualizar reuniões na vista de agenda, o horário deixará de ter prioridade, exibindo o título.`,
			},
		],
	},
	{
		version: "0.3.2",
		news: [
			{
				title: "📃 Abonos de faltas",
				text: "Agora é possível solicitar um abono de falta, a partir de uma lista predefinida de situações. Para solicitar, acesse o Menu do usuário.",
			},
			{
				title: "⌚ Minhas jornadas",
				text: "A partir da visualização da sua jornada de trabalho, agora é possível visualizar as jornadas configuradas anteriormente.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Uma atividade não mais deixará de salvar caso o número de itens no checklist for menor que o total inicial`,
			},
		],
	},
	{
		version: "0.3.1",
		news: [
			{
				title: "🖥️ Drag and Drop",
				text: "A função de arrastar e soltar está disponível no Kanban!",
			},
			{
				title: "⌚ Reunião ou atividade?",
				text: "Um ícone identificará as reuniões entre as atividades.",
			},
			{
				title: "📄📄 Duplicar atividade",
				text: "Adicionada a função de duplicar uma atividade!",
			},
			{
				title: "🔝 Melhorias gerais nas atividades",
				text: "Quantidade de caracteres na descrição, quebras de linha visíveis, título mais evidente, tabela compacta mais compacta ainda, busca por nomes com acento e mais outras coisas.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- O navegador não vai mais travar ao tentar visualizar as semanas de um usuário (Banco de Horas - Admin).<br>
							- As tarefas próximas e anteriores não aparecerão mais com datas inválidas nem repetidas.<br>
							- O menu do usuário não abria após acessar a página de Atividades. Corrigido!<br>
							- A agenda não mais duplicará as reuniões ao aplicar um filtro.`,
			},
		],
	},
	{
		version: "0.3",
		news: [
			{
				title: "🖥️ Atividades",
				text: "Organize-se em suas atividades (sozinho, em equipe, num projeto ou não) e marque reuniões com seus colegas! Você também pode lançar horas em uma atividade (elas não estão relacionadas aos seus registros de entrada e saída).",
			},
			{
				title: "📝 Ajustes",
				text: "Agora é necessário que a justificativa de um ajuste tenha pelo menos 15 caracteres.",
			},
			{
				title: "📦 Projetos",
				text: "Projetos agrupam informações importantes, atividade, anexos, horas lançadas e outros projetos!",
			},
			{
				title: "🔢 Quantidade de resultados",
				text: "Você pode escolher quantos resultados serão exibidos nas listagems! Opções disponíveis: 15, 30 e 50.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- A paginação da tela não mais sumirá ao visualizar a semana de um usuário (Banco de Horas - Admin).<br>
							- Os minutos não mais aparecerão negativos se o total das horas for negativo (Banco de Horas - Admin).<br>
							- Ao fazer um registro de entrada em suas férias, o sistema não mais pedirá para justificar um atraso.<br>
							- As notificações não virão mais em formato 12h.<br>
							- Os seletores de data e hora agora estão corretamente integrados ao modo Dark.`,
			},
		],
	},
	{
		version: "0.2.2",
		news: [
			{
				title: "📄 Justificativas e ajustes",
				text: "Justificativas agora são visíveis na tabela de registros. Agora será mostrado quem aprovou/reprovou um ajuste e em que momento.",
			},
			{
				title: "🔝 Melhorias",
				text: "Você agora será notificado pelo Windows quando chegar o momento de bater seu ponto!",
			},
			{
				title: "🔴 Notificações",
				text: "Você será avisado pelo OwnTime sobre aniversários, feriados, férias, atrasos que precisam ser justificados e (em breve) atividades, reuniões e projetos.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Clicar em cancelar uma revisão de banco de horas não mais confirma ela. <br>
							- A Minha Jornada agora abrirá corretamente no mobile. <br>
							- O tema Dark agora está corretamente implementado na tela de Configurar Nova Senha.<br>
							- Registros ajustados não mais pedirão para justificar um atraso.`,
			},
		],
	},
	{
		version: "0.2.1",
		news: [
			{
				title: "📱 Adaptação Mobile",
				text: "O OwnTime agora pode ser utilizado sem problemas em dispositivos móveis.",
			},
			{
				title: "🔝 Melhorias",
				text: "Você agora consegue visualizar sua própria jornada de trabalho a partir do menu lateral.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Um usuário excluído não continuará mais como líder de equipe. <br>
							- Ajustes deixaram de ser considerados inválidos se outro registro existe na mesma data.<br>
							- O número de páginas de Banco de Horas não são mais fixadas em 100.<br>
							- A ordenação dos Meus Registros não leva mais em consideração a data anterior à um ajuste.`,
			},
		],
	},
	{
		version: "0.2",
		news: [
			{
				title: "🌙 Tema Dark",
				text: "Você agora pode alternar entre os temas Light e Dark usando o botão no canto superior direito.",
			},
			{
				title: "📄 Exportação em Excel (Admin)",
				text: "Administradores podem exportar dados de registros dos usuários e de banco de horas simplesmente clicando em um botão.",
			},
			{
				title: "🆕 Novidades da versão",
				text: "Exatamente esta tela que você está lendo :)",
			},
			{
				title: "🔝 Melhorias",
				text: "Você agora consegue visualizar sua própria jornada de trabalho a partir do menu lateral.",
			},
			{
				title: "🔧 Correção de bugs",
				text: `Bugs corrigidos: <br>
							- Formato da hora na tela Admin/Registros estava usando o formato de 12h ao invés de 24h. <br>
							- Listagem dos usuários ao criar ou editar uma equipe não estava funcionando do jeito esperado. <br>
							- O botão cancelar da tela de Criar Feriado estava criando o feriado. <br>
							- O banco de horas estava sendo calculado de maneira estranha após realizar um ajuste para uma data anterior.`,
			},
		],
	},
	{
		version: "0.1",
		news: [
			{
				title: "Registros",
				text: "Adicionada funcionalidade de registros de Entrada e Saída (o famoso \"Bater ponto\") e justificar atrasos.",
			},
			{
				title: "Ajustes de registros",
				text: "Adicionada funcionalidade de solicitar um ajuste no horário de um registro, caso você tenha esquecido de bater o ponto no momento certo.",
			},
			{
				title: "Banco de horas",
				text: "Seu banco de horas é calculado automaticamente baseado nas horas demandadas e horas trabalhadas.",
			},
			{
				title: "Usuários",
				text: "Listagem de usuários. Administradores podem editar e criar usuários, além de definir período de férias.",
			},
			{
				title: "Equipes",
				text: "Equipes serão utilizadas com maior frequência no futuro, mas já estão implementadas. Toda equipe precisa de um líder.",
			},
			{
				title: "Feriados",
				text: "Feriados podem ser configurados por um administrador. Nenhuma hora é demandada de colaboradores em feriados. Feriados podem ser anuais ou não.",
			},
			{
				title: "Aprovação de Ajustes (Admin)",
				text: "Listagem de ajustes solicitados pelos usuários. Administradores podem aprovar ou reprovar um ajuste.",
			},
			{
				title: "Registros de usuários (Admin)",
				text: "Listagem de todos os registros de todos os usuários.",
			},
			{
				title: "Banco de horas de usuários (Admin)",
				text: "Listagem de todos bancos de horas de todos os usuários.",
			},
			{
				title: "Configurações (Admin)",
				text: "Algumas configurações de tempos de tolerância de: Atraso, Horas extras e Ausências.",
			},
		],
	},
];