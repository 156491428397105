<template>
	<Teleport to="body">
		<dialog class="modal-background" id="taskModal" ref="taskModal" tabindex="-1" aria-labelledby="taskModalLabel" aria-hidden="true" @keydown="blockEsc">
			<section class="modal-content" :class="isCompact ? 'modal-lg': 'modal-xl'">

				<!--Criação e edição de atividade-->
				<div v-if="isEditing || isCreating" @click="closeSelectors">

					<!--Modal header-->
					<div class="modal-header">
						<OwnIcon
							:type="currentTask.IsPrivate ? 'lock' : 'earth'" large
							:title="`A ${currentTask.TaskType === TaskTypes.Task ? 'atividade' : 'reunião'} é ${currentTask.IsPrivate ? 'privada' : 'pública'}`"
						/>

						<input class="form-control form-control-lg" v-model="currentTask.Name" maxlength="100" placeholder="Insira o título da atividade">

						<button type="button" class="btn-close" @click="closeTaskModal">
							<span hidden aria-hidden="true">Fechar</span>
						</button>
					</div>

					<!--Modal body-->
					<div class="modal-body" @dragenter.prevent @dragover.prevent @drop.stop="isDragging = false">
						<div v-if="isCreating" class="form-check form-switch">
							<!--suppress HtmlUnknownAttribute -->
							<input class="form-check-input" type="checkbox" role="switch" id="checklistTaskType"
							       v-model="currentTask.TaskType" :true-value="TaskTypes.Meeting"
							       :false-value="TaskTypes.Task">
							<label class="form-check-label" for="checklistTaskType">Reunião</label>
						</div>
						<div v-if="isEditing" class="form-check form-switch">
							<label class="form-check-label" for="checklistTaskType">
								<!--suppress HtmlUnknownAttribute -->
								<input class="form-check-input" type="checkbox" role="switch" id="checklistTaskType"
								       v-model="currentTask.TaskType" :true-value="TaskTypes.Meeting"
								       :false-value="TaskTypes.Task">
								Reunião</label>
						</div>
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" role="switch" id="isPrivate"
							       v-model="currentTask.IsPrivate">
							<label
								class="form-check-label"
								for="isPrivate"
								:title="`A ${currentTask.TaskType === TaskTypes.Task ? 'atividade' : 'reunião'} privada será visivel apenas pelos ${currentTask.TaskType === TaskTypes.Task ? 'membros' : 'convocados'}`">
								Privada
							</label>
						</div>

						<p>Etiquetas</p>

						<section class="task-tags">
							<button type="button" class="btn btn-secondary btn-round btn-sm"
							        :class="{opened: addTagOpen}" @click.stop="openTagSelector">
								<OwnIcon type="add" class="add-tag" />
							</button>

							<template v-if="showTags">
							<span class="tag-label-container" v-for="(tag, i) in currentTask.Tags" :key="i">
								<TagLabel :tag="tag" :is-editting="true" @click-remove="addTag" />
							</span>
							</template>

							<TagComponent v-if="addTagOpen" selector :tags-in-task="currentTask.Tags"
							              :task-team="String(currentTask.TeamId)" @click.stop
							              @add-tag-to-task="addTag" />
						</section>

						<p> {{ currentTask.TaskType === TaskTypes.Task ? "Membros" : "Convocados" }} </p>

						<div v-if="limitParticipants && currentTask.TaskType === TaskTypes.Meeting" class="alert alert-warning fade show" role="alert">
							<strong>Atenção!</strong> A reunião está com o número de membros acima da capacidade máxima do local.
						</div>

						<div v-if="availableMeetingTimes != '' && currentTask.TaskType === TaskTypes.Meeting" class="alert alert-primary show" role="alert">
							{{ availableMeetingTimes }}
						</div>

						<section v-if="alertItems.length > 0 && currentTask.TaskType === TaskTypes.Meeting" class="alert-messages">
							<div v-for="(item, i) in alertItems" :key="i" role="alert" :class="item.class">
								<span><OwnIcon :type="item.iconType" line />
									<strong>Atenção!</strong>
									{{ item.message }}</span>
							</div>
						</section>

						<div class="members">
							<button type="button" class="btn btn-secondary btn-round" :class="{opened : addMemberOpen}"
							        @click.stop="openUserSelector">
								<OwnIcon type="add" class="add-member" />
							</button>
							<div class="integrant-pics-container" v-for="(user, i) in currentTask.UsersInfo" :key="i">
								<UserImage id="integrant-pics" size="large" :name="user.Name" :img-url="user.ImageUrl" />
								<span v-if="currentTask.TaskType === TaskTypes.Meeting" :class="checkConflictForUserPictures(user.Id)" />
								<OwnIcon type="delete-bin" class="integrant-delete" :title="'Remover ' + user.Name" @click="excludeTaskMember(i)" />
							</div>

							<AddMemberComponent v-if="addMemberOpen" :user-available-list="userAvailableList"
							                    @click.stop @user-added="addMember" @all-users-added="addAllMembers" />
						</div>

						<div class="task-item">
							<div>
								<label for="begin-date" class="form-label">
									{{ currentTask.TaskType === TaskTypes.Task ? "Data de início" : "Horário" }}
								</label>
								<input id="begin-date" class="form-control" type="datetime-local"
								       v-model="currentTask.BeginDate">
							</div>

							<div>
								<label for="end-date" class="form-label">
									{{ currentTask.TaskType === TaskTypes.Task ? "Data de entrega" : "Encerramento" }}
								</label>
								<input id="end-date" class="form-control" type="datetime-local"
								       v-model="currentTask.EndDate">
							</div>

							<div>
								<label for="state" class="form-label">Etapa</label>
								<select id="state" class="form-select" v-model="currentTask.Stage">
									<option :value="StageTypes.Backlog">Backlog</option>
									<option :value="StageTypes.AFazer">A fazer</option>
									<option :value="StageTypes.Fazendo">Fazendo</option>
									<option :value="StageTypes.Verificar">Verificar</option>
									<option :value="StageTypes.Feito">Feito</option>
								</select>
							</div>
						</div>

						<div class="task-item">
							<div>
								<label for="project" class="form-label">
									<OwnIcon type="folder-2" />
									Projeto
								</label>

								<select v-if="isLoading" id="project" class="form-select">
									<option selected>Carregando...</option>
								</select>

								<select v-else id="project" class="form-select" v-model="currentTask.ProjectId">
									<option v-for="(project, idx) in projects" :key="idx" :value="project.ProjectId">
										{{ project.Name }}
									</option>
								</select>
							</div>

							<div>
								<label for="team" class="form-label">
									<OwnIcon type="group-2" />
									Equipe
								</label>

								<select v-if="isLoading" id="team" class="form-select">
									<option selected>Carregando...</option>
								</select>

								<select v-else id="team" class="form-select" v-model="currentTask.TeamId">
									<option v-for="(team, idx) in teams" :key="idx" :value="team.TeamId">{{
											team.Name
									                                                                     }}
									</option>
								</select>
							</div>

							<div>
								<label class="form-label" for="effort-input">
									<OwnIcon type="timer-flash" />
									Esforço
								</label>
								<input class="form-control" type="number" id="effort-input" v-model="currentTask.Effort"
								       placeholder="Ex.: 20 (horas)" min="0">
							</div>
						</div>

						<div class="task-item">
							<div>
								<label for="points" class="form-label">
									<OwnIcon type="bookmark" />
									Pontos
								</label>
								<datalist id="tickmarks">
									<option value="1" label="1"></option>
									<option value="2" label="2"></option>
									<option value="3" label="3"></option>
									<option value="4" label="5"></option>
									<option value="5" label="8"></option>
									<option value="6" label="13"></option>
								</datalist>
								<input id="points" type="range" min="1" max="6"
								       v-model.number="currentTask.SliderPoints" class="form-range" list="tickmarks">
							</div>

							<div v-if="currentTask.TaskType === TaskTypes.Meeting">
								<label class="form-label">Tipo</label>
								<div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="inlineRadioOptions"
										       id="presential" :value="MeetingTypes.MeetingRoom"
										       v-model="currentTask.MeetingType">
										<label class="form-check-label" for="presential">
											<OwnIcon type="map-pin-2" fill />
											Presencial</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="inlineRadioOptions"
										       id="remote" :value="MeetingTypes.Remote"
										       v-model="currentTask.MeetingType">
										<label class="form-check-label" for="remote">
											<OwnIcon type="computer" />
											Remoto
										</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="inlineRadioOptions"
										       id="external" :value="MeetingTypes.External"
										       v-model="currentTask.MeetingType">
										<label class="form-check-label" for="external">
											<OwnIcon type="flight-takeoff" />
											Externo
										</label>
									</div>
								</div>
							</div>

							<div>
								<div v-if="currentTask.TaskType === TaskTypes.Meeting">
									<label for="meetingRoom" class="form-label">{{
											currentTask.MeetingType === MeetingTypes.Remote ? "Link" : "Local"
									                                            }}</label>
									<select v-if="isLoading" id="meetingRoom" class="form-select">
										<option selected>Carregando...</option>
									</select>
									<select id="meetingRoom"
									        v-else-if="currentTask.MeetingType === MeetingTypes.MeetingRoom"
									        class="form-select" v-model="currentTask.MeetingRoom.meetingRoomId">
										<option hidden :value="null">Selecione uma sala de reunião</option>
										<option v-for="(meetingRoom, idx) in allMeetingRooms" :key="idx"
										        :value="meetingRoom.meetingRoomId">
											{{ meetingRoom.name }} (máx. {{ meetingRoom.maxParticipant }} pessoas)
										</option>
									</select>
									<input v-else id="place" class="form-control" type="text"
									       v-model="currentTask.Place" required>
								</div>
							</div>
						</div>

						<div class="task-item">
							<label for="description" class="form-label">Descrição</label>
						</div>

						<MarkdownEditor :text="currentTask.Description" @text-updated="onMarkdownTextUpdate" />

						<div class="checklist">
							<span>Checklist {{ checklistItensDone }}/{{ currentTask.CheckListItems.length }}</span>
							<section :class="{dragging: isDragging}"
							         v-for="(checklist, idx) in currentTask.CheckListItems" :key="checklist.Record"
							         :draggable="canDragChecklist"
							         @dragover.prevent
							         @dragenter="setChecklistItemHovering($event, idx)"
							         @dragleave.stop="removeChecklistItemHovering($event)"
							         @dragstart.stop="startDrag($event, idx)"
							         @drop.stop="onDrop($event, idx)">
								<div class="hover-container">
									<div class="hover"
									     :class="{show: idx === checklistHoveringIdx && isDragging}"></div>
								</div>
								<div class="form-check editting"
								     :class="{'dragging-me': isDragging && idx === currentChecklistIdx}">
									<input class="form-check-input" v-model="checklist.Done" type="checkbox"
									       :id="'checklist' + idx">
									<input class="form-control form-control-sm" type="text"
									       v-model="checklist.Description" @focus="canDragChecklist = false" maxlength="255"
									       @blur="canDragChecklist = true">
									<button class="btn btn-invisible exclude" @click="excludeChecklistItem(idx)"
									        title="Excluir">
										<OwnIcon type="delete-bin-2" />
									</button>
									<OwnIcon type="draggable" no-fill-no-line />
								</div>
							</section>
							<button class="btn btn-secondary btn-sm" @click="newChecklistItem">Adicionar item</button>
						</div>
					</div>

					<!--Modal footer-->
					<div class="modal-footer" v-if="isCreating">
						<!--suppress TypeScriptUnresolvedReference -->
						<button class="btn btn-secondary" type="button" @click="closeTaskModal">
							Cancelar
						</button>

						<button class="btn btn-primary" :disabled="!canSendRequest" @click="createTask" type="submit"
						        aria-label="Criar">
							<OwnIcon type="add" />
							Criar
						</button>
					</div>

					<div class="modal-footer" v-else>
						<button v-if="currentTask.IsArchived" class="btn btn-light" type="button" @click="openUnarchiveTaskModal">
							<OwnIcon type="inbox-unarchive" />
							Desarquivar
						</button>

						<button v-else class="btn btn-dark" type="button" @click="openArchiveTaskModal">
							<OwnIcon type="archive" />
							Arquivar
						</button>

						<button class="btn btn-secondary" type="button" @click="cancelEdit">
							Cancelar
						</button>

						<button class="btn btn-primary" @click="editTask" type="submit" aria-label="Salvar" :disabled="isLoading">
							<OwnIcon type="check" />
							Salvar
						</button>
					</div>
				</div>

				<!--Visualização de atividade-->
				<div v-else>

					<!--Modal header-->
					<div class="modal-header">
						<p class="task-name">
							<OwnIcon :type="currentTask.IsPrivate ? 'lock' : 'earth'" small
							         :title="`A ${currentTask.TaskType === TaskTypes.Task ? 'atividade' : 'reunião'} é ${currentTask.IsPrivate ? 'privada' : 'pública'}`" />

							{{ (currentTask.TaskType === TaskTypes.Meeting ? "Reunião: " : "") + currentTask.Name }}

							<button class="btn btn-invisible" id="duplicate" v-if="currentTask.CanEditTask" title="Duplicar atividade" @click="openDuplicateTaskModal">
								<OwnIcon type="file-copy" />
							</button>
						</p>

						<div v-if="isLoading">
							<span class="spinner-border spinner-border-sm" role="status"></span>
							Carregando...
						</div>
						<button type="button" class="btn-close" @click="closeTaskModal"></button>
					</div>

					<template v-if="!registerHour">
						<!--Modal body-->
						<div class="modal-body">
							<section class="task-tags" v-if="showTags">
							<span class="tag-label-container" v-for="(tag, i) in currentTask.Tags" :key="i">
								<TagLabel :tag="tag" />
							</span>
							</section>

							<template v-if="currentTask.TaskType === TaskTypes.Task">
								<p>Membros</p>

								<div class="members">
									<UserImage size="large" v-for="(user, i) in currentTask.UsersInfo" :key="i"
									           :name="user.Name" :img-url="user.ImageUrl" />
								</div>
							</template>

							<template v-else>
								<div v-if="limitParticipants" class="alert alert-warning fade show" role="alert">
									<strong>Atenção!</strong> A reunião está com o número de membros acima da capacidade máxima do local.
								</div>

								<template v-if="currentTask.TaskMembersConfirmed.length !== 0">
									<p>Confirmados</p>
									<div class="members">
										<div class="integrant-pics-container" v-for="(user, i) in currentTask.TaskMembersConfirmed"
										     :key="i">
											<UserImage size="large" :name="user.Name" :img-url="user.ImageUrl" />
											<span v-if="currentTask.TaskType === TaskTypes.Meeting" :class="checkConflictForUserPictures(user.Id)" />
										</div>
										<button class="btn btn-secondary" v-if="currentTask.CanDesconfirm"
										        @click="confirmPresenceMeeting(false)" :disabled="isLoading">
											Desconfirmar
										</button>
									</div>
								</template>

								<template v-if="currentTask.TaskMembersUnconfirmed.length !== 0">
									<p>Aguardando</p>
									<div class="members">
										<div class="integrant-pics-container" v-for="(user, i) in currentTask.TaskMembersUnconfirmed"
										     :key="i">
											<UserImage size="large" :name="user.Name" :img-url="user.ImageUrl" />
											<span v-if="currentTask.TaskType === TaskTypes.Meeting" :class="checkConflictForUserPictures(user.Id)" />
										</div>
										<button class="btn btn-secondary" v-if="currentTask.CanConfirm"
										        @click="confirmPresenceMeeting(true)" :disabled="isLoading">Confirmar
										</button>
									</div>
								</template>
							</template>

							<div class="task-item">
								<div>
									<label class="form-label">
										{{ currentTask.TaskType === TaskTypes.Task ? "Data de início" : "Horário" }}
									</label>
									<p>
										{{
											currentTask.BeginDate ? moment(currentTask.BeginDate).locale("pt-br").format("lll") : "-"
										}}
									</p>
								</div>

								<div>
									<label class="form-label">
										{{
											currentTask.TaskType === TaskTypes.Task ? "Data de entrega" : "Encerramento"
										}}
									</label>
									<p :class="currentTask.IsLate && currentTask.Stage !== StageTypes.Feito? 'late' : '' ">
										{{
											currentTask.EndDate ? moment(currentTask.EndDate).locale("pt-br").format("lll") : "-"
										}}
									</p>
								</div>

								<div>
									<label class="form-label">
										Etapa
									</label>
									<p v-if="currentTask.Stage === StageTypes.Backlog">Backlog</p>
									<p v-else-if="currentTask.Stage === StageTypes.AFazer">A fazer</p>
									<p v-else-if="currentTask.Stage === StageTypes.Fazendo">Fazendo</p>
									<p v-else-if="currentTask.Stage === StageTypes.Verificar">Verificar</p>
									<p v-else>Feito</p>
								</div>
							</div>

							<div class="task-item">
								<div>
									<label class="form-label">
										<OwnIcon type="folder-2" />
										Projeto
									</label>
									<p>
										{{ currentTask.ProjectName ?? "-" }}
										<router-link v-if="currentTask.ProjectId"
										             :to="{name: RouteName.VISUALIZE_PROJECT, params: {projectId: currentTask.ProjectId}}"
										             target="_blank" class="external-link">
											<OwnIcon type="external-link" />
										</router-link>
									</p>
								</div>

								<div>
									<label class="form-label">
										<OwnIcon type="group-2" />
										Equipe
									</label>
									<p>
										{{ currentTask.TeamName ?? "-" }}
										<router-link v-if="currentTask.TeamId"
										             :to="{name: RouteName.VISUALIZE_TEAM, params: {TeamId: currentTask.TeamId}}"
										             target="_blank" class="external-link">
											<OwnIcon type="external-link" />
										</router-link>
									</p>
								</div>

								<div>
									<label class="form-label">
										<OwnIcon type="timer-flash" />
										Esforço
									</label>
									<p>{{ currentTask.EffortText }}</p>
								</div>
							</div>

							<div class="task-item">
								<div>
									<label for="points" class="form-label">
										<OwnIcon type="bookmark" />
										Pontos
									</label>
									<datalist id="tickmarks">
										<option value="1" label="1"></option>
										<option value="2" label="2"></option>
										<option value="3" label="3"></option>
										<option value="4" label="5"></option>
										<option value="5" label="8"></option>
										<option value="6" label="13"></option>
									</datalist>
									<input disabled id="points" type="range" min="1" max="6"
									       v-model.number="currentTask.SliderPoints" class="form-range"
									       list="tickmarks">
								</div>

								<div v-if="currentTask.TaskType === TaskTypes.Meeting">
									<label class="form-label" for="task-type">Tipo</label>
									<p id="task-type">
										<OwnIcon type="computer" v-if="currentTask.MeetingType === MeetingTypes.Remote" />
										<OwnIcon type="flight-takeoff" fill v-else-if="currentTask.MeetingType === MeetingTypes.External" />
										<OwnIcon type="map-pin-2" fill v-else />
										{{ meetingTypes }}
									</p>
								</div>

								<div v-if="currentTask.TaskType === TaskTypes.Meeting">
									<template v-if="currentTask.MeetingType === MeetingTypes.Remote">
										<label for="link" class="form-label">
											Link
										</label>
										<a id="link" :href="currentTask.Place">{{ currentTask.Place }}</a>
									</template>
									<template v-else-if="currentTask.MeetingType === MeetingTypes.External">
										<label for="external" class="form-label">
											Local
										</label>
										<p id="external">{{ currentTask.Place }}</p>
									</template>
									<template v-else>
										<label for="meetingRoom" class="form-label">
											Local
										</label>
										<p id="meetingRoom">{{ currentTask.MeetingRoom.name }}</p>
									</template>
								</div>
							</div>

							<div class="task-item">
								<label class="form-label">Descrição</label>
							</div>

							<MarkdownEditor preview :text="currentTask.Description" />

							<div class="checklist" v-if="currentTask.CheckListItems.length > 0">
								<span>Checklist {{ checklistItensDone }}/{{ currentTask.CheckListItems.length }}</span>

								<div v-for="(checklist, idx) in currentTask.CheckListItems" :key="checklist.Record"
								     class="form-check">
									<input class="form-check-input" v-model="checklist.Done" type="checkbox"
									       :id="'checklist' + idx" :disabled="!currentTask.CanEditTask"
									       @change="changeStateChecklist(checklist)">
									<!--<input class="form-control form-control-sm" type="text" :value="checklist.Description" disabled>-->
									<label class="form-check-label" :for="'checklist' + idx">
										{{ checklist.Description }}
									</label>
								</div>
							</div>
						</div>

						<!--Modal footer-->
						<div class="modal-footer">
							<button class="btn btn-secondary me-auto btn-log" :disabled="isLoading"
							        @click="logOpen = !logOpen">
								<OwnIcon type="compasses-2" />
								Histórico de alterações
								<OwnIcon v-if="!logOpen" type="arrow-down-s"></OwnIcon>
								<OwnIcon v-else type="arrow-up-s"></OwnIcon>
							</button>

							<button class="btn btn-secondary" @click="registerHour = true" :disabled="isLoading">
								<OwnIcon type="time" />
								Registrar horas
							</button>

							<button v-if="currentTask.CanEditTask" class="btn btn-primary" @click="startEdit"
							        :disabled="isLoading">
								<OwnIcon type="pencil" />
								Editar
							</button>
						</div>

						<LogComponent v-if="logOpen"
						              :task-id="currentTask.TaskId"
						              :creator="currentTask.CreatedBy"
						              :created-date="currentTask.CreatedDate">
						</LogComponent>
					</template>

					<!--Lançamento de horas-->
					<template v-else>

						<!--Modal body-->
						<div class="modal-body">
							<div class="add-hours">
								<input :disabled="!currentTask.CanAddHours" class="form-control" type="date"
								       v-model="newRegisterHours.date">
								<input :disabled="!currentTask.CanAddHours" class="form-control" type="time"
								       v-model="newRegisterHours.time">
								<button :disabled="!currentTask.CanAddHours" class="btn btn-primary"
								        @click.prevent="createRecordTime">
									<OwnIcon type="time" />
									Registrar
								</button>
							</div>

							<h5>Horas registradas</h5>

							<section class="table-container table-hours">
								<div v-if="isLoading">
									<span class="spinner-border spinner-border-sm" role="status"></span>
									Carregando...
								</div>
								<table v-else class="table table-responsive">
									<thead>
									<tr>
										<th>Usuário</th>
										<th>Data</th>
										<th>Tempo</th>
										<th></th>
									</tr>
									</thead>
									<tbody>
									<tr v-if="taskRegisteredHours.length === 0">
										<td colspan="4" class="text-center">
											Ainda não há lançamentos de horas nesta atividade
										</td>
									</tr>
									<tr v-for="(record, idx) in taskRegisteredHours" :key="idx">
										<td>
											<div class="tdUserImage">
												<UserImage size="medium" :name="record.Name" :img-url="record.ImageUrl" big />
											</div>
										</td>
										<td>{{ record.Date }}</td>
										<td>{{ record.Time }}</td>
										<td>
											<button v-if="record.CanDelete" class="btn btn-invisible exclude" title="Excluir" @click="getRecordedTimeToDelete(record)">
												<OwnIcon type="delete-bin-2" />
											</button>
										</td>
									</tr>
									</tbody>
								</table>
							</section>

							<h5>Horas totais</h5>

							<section class="table-container table-hours">
								<div v-if="isLoadingTotalHours">Carregando...</div>
								<table v-else class="table table-responsive">
									<thead>
									<tr>
										<th>Usuário</th>
										<th>Horas totais</th>
									</tr>
									</thead>
									<tbody>
									<tr v-if="taskRegisteredUserTotalHours.length === 0">
										<td colspan="2" class="text-center">Ainda não há lançamentos de horas nesta
										                                    atividade
										</td>
									</tr>
									<tr v-for="(userRecordedTotalTime, idx) in taskRegisteredUserTotalHours" :key="idx">
										<td>
											<div class="tdUserImage">
												<UserImage size="medium" :name="userRecordedTotalTime.Name" :img-url="userRecordedTotalTime.ImageUrl" big />
											</div>
										</td>
										<td>{{ userRecordedTotalTime.Time }}</td>
									</tr>
									</tbody>
								</table>
							</section>
						</div>

						<!--Modal footer-->
						<div class="modal-footer">
							<button class="btn btn-secondary" @click="registerHour = false">Voltar</button>
						</div>
					</template>
				</div>
			</section>
		</dialog>
	</Teleport>

	<!-- Modal Duplicação -->
	<ModalComponent id="duplicateModal" ref="duplicateModal" modal-size="modal-sm">
		<template #header>
			Duplicar {{ currentTask.Name }}?
		</template>

		<template #body>
			<p>Todas as informações da atividade atual serão copiadas.</p>
		</template>

		<template #footer>
			<button class="btn btn-secondary" @click="closeDuplicateTaskModal">
				Cancelar
			</button>
			<button @click="duplicateTask" class="btn btn-primary">
				<OwnIcon type="file-copy" />
				Duplicar
			</button>
		</template>
	</ModalComponent>

	<!-- Modal Arquivar -->
	<ModalComponent id="archiveModal" ref="archiveModal">
		<template #header>
			Arquivar {{ currentTask.Name }}?
		</template>

		<template #body>
			<p>Esta ação só poderá ser desfeita por um administrador</p>
		</template>

		<template #footer>
			<button class="btn btn-secondary" @click="closeArchiveTaskModal">
				Cancelar
			</button>
			<button @click="archiveTask" class="btn btn-dark">
				<OwnIcon type="archive" />
				Arquivar
			</button>
		</template>
	</ModalComponent>

	<!-- Modal Desarquivar -->
	<ModalComponent id="unarchiveModal" ref="unarchiveModal" no-body>
		<template #header>
			Desarquivar {{ currentTask.Name }}?
		</template>

		<template #footer>
			<button class="btn btn-secondary" @click="closeUnarchiveTaskModal">
				Cancelar
			</button>
			<button @click="unarchiveTask" class="btn btn-light">
				<OwnIcon type="inbox-unarchive" />
				Desarquivar
			</button>
		</template>
	</ModalComponent>

	<!-- Modal Excluir hora registrada -->
	<ModalComponent id="deleteRecordedTimeModal" ref="deleteRecordedTimeModal" modal-size="modal-sm">
		<template #header>
			Excluir {{ recordedTimeToDelete.Time }}?
		</template>

		<template #body>
			<p>Esta ação não poderá ser desfeita.</p>
		</template>

		<template #footer>
			<button class="btn btn-secondary" @click="closeDeleteRecordedTimeModal">
				Cancelar
			</button>
			<button @click="deleteRecordedTime(recordedTimeToDelete)" class="btn btn-danger">
				<OwnIcon type="delete-bin" />
				Excluir
			</button>
		</template>
	</ModalComponent>

</template>

<script setup lang="ts">

import { computed, onMounted, ref, watch, watchEffect } from "vue";
import EditTaskRequest, { MeetingTypes, TaskTypes } from "@/api/requests/task/EditTaskRequest";
import { CheckListItem, MemberInfo, pointsBackToFront, pointsFrontToBack, TasksView } from "@/models/Tasks/TasksView";
import TagLabel from "@/components/TagLabel.vue";
import TagComponent from "@/components/TagComponent.vue";
import UserImage from "@/components/UserImage.vue";
import AddMemberComponent from "@/components/AddMemberComponent.vue";
import { Tag } from "@/models/Tasks/Tag";
import { notify } from "@kyvg/vue3-notification";
import { checkGUID, DefaultError, DefaultWarn, getImageURL, NotificationType } from "@/utils/general";
import GetTaskRequest, { StageTypes } from "@/api/requests/task/GetTaskRequest";
import { Project } from "@/models/Projects/Project";
import GetProjectsInfoRequest from "@/api/requests/project/GetProjectsInfoRequest";
import GetTeamsInfoRequest from "@/api/requests/teams/GetTeamsInfoRequest";
import { Team } from "@/models/Admin/Teams";
import { MeetingRooms } from "@/models/Admin/MeetingRooms";
import GetMeetingRoomListRequest from "@/api/requests/meeting-room/GetMeetingRoomListRequest";
import MarkdownEditor from "@/components/MarkdownEditor.vue";
import { loadStart, loadStop } from "@/utils/loading";
import CreateTaskRequest from "@/api/requests/task/CreateTaskRequest";
import EditMeetingConfirmationRequest from "@/api/requests/meeting/EditMeetingConfirmationRequest";
import moment from "moment/moment";
import RouteName from "@/router/routes-name";
import EditStateChecklistRequest from "@/api/requests/task/EditStateChecklistRequest";
import LogComponent from "@/components/LogComponent.vue";
import CreateTaskRecordTimeRequest from "@/api/requests/task/CreateTaskRecordTimeRequest";
import GetTaskRecordedTimeRequest from "@/api/requests/task/GetTaskRecordedTimeRequest";
import { TaskRecordedTime } from "@/models/Tasks/TaskRecordedTime";
import GetUserHoursTaskRecordedTime from "@/api/requests/task/GetUserHoursTaskRecordedTime";
import { useStore } from "vuex";
import { AuthGetter } from "@/store/modules/auth/getters";
import router from "@/router";
import DeleteTaskRecordedTimeRequest from "@/api/requests/task/DeleteTaskRecordedTimeRequest";
import { useRoute } from "vue-router";
import DeleteTaskRequest from "@/api/requests/task/DeleteTaskRequest";
import UnarchiveTaskRequest from "@/api/requests/task/UnarchiveTaskRequest";
import GetTaskAvailableMeetingTimes from "@/api/requests/task/GetTaskAvailableMeetingTimes";
import OwnIcon from "@/components/OwnIcon.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import GetUserMeetingAvailabillityRequest from "@/api/requests/users/GetUserMeetingAvailabillityRequest";
import { BusyTimes, BusyTimesTypes, UserInfoMeetings } from "@/models/User/UserInfo";

const limitParticipants = computed(() =>
	currentTask.value.MeetingRoom.meetingRoomId !== null &&
	currentTask.value.TaskType === TaskTypes.Meeting &&
	currentTask.value.MeetingType === MeetingTypes.MeetingRoom &&
	currentTask.value.MeetingRoom.maxParticipant !== 0 &&
	currentTask.value.MeetingRoom.maxParticipant !== null &&
	currentTask.value.MeetingRoom.maxParticipant < currentTask.value.UsersInfo.length);

const checklistItensDone = computed(() => currentTask.value.CheckListItems.filter(item => item.Done).length);
const canSendRequest = computed(() => currentTask.value.Name.length > 0);

const addTagOpen = ref(false);
const addMemberOpen = ref(false);
const isDragging = ref(false);
const totalRecords = ref(0);
const canDragChecklist = ref(false);
const checklistHoveringIdx = ref(-1);
const currentChecklistIdx = ref(-1);
const isLoading = ref(false);
const isEditing = ref(false);
const isCreating = ref(false);
const registerHour = ref(false);
const logOpen = ref(false);

// Salvos no Local Storage
const isCompact = ref(false);
const showTags = ref(false);

// Variaveis do modal
const taskModal = ref<HTMLDialogElement>();

const duplicateModal = ref<InstanceType<typeof ModalComponent>>();
const archiveModal = ref<InstanceType<typeof ModalComponent>>();
const unarchiveModal = ref<InstanceType<typeof ModalComponent>>();
const deleteRecordedTimeModal = ref<InstanceType<typeof ModalComponent>>();
const isModalOpen = ref(false);

const userAvailableList = ref<MemberInfo[]>([]);
const allUsers = ref<UserInfoMeetings[]>([]);
const projects = ref<Project[]>([]);
const teams = ref<Team[]>([]);
const allMeetingRooms = ref<MeetingRooms[]>([]);
const currentTask = ref<TasksView>(new TasksView());
const currentTaskBackup = ref<TasksView>(new TasksView());
const taskRegisteredHours = ref<TaskRecordedTime[]>([]);
const taskRegisteredUserTotalHours = ref<TaskRecordedTime[]>([]);
const isLoadingTotalHours = ref(false);
const totalHoursRegistered = ref(0);
const hoursRegistered = ref(0);
const recordedTimeToDelete = ref<TaskRecordedTime>(new TaskRecordedTime());

const store = useStore();
const route = useRoute();
const currentUser = computed(() => store.getters[AuthGetter.USER_INFO]);
const userId = currentUser.value.UserId;

const newRegisterHours = ref({
	date: moment().format("YYYY-MM-DD"),
	time: "",
});

const meetingTypes = computed(() => {
	if (currentTask.value.MeetingType === MeetingTypes.Remote)
		return "Remoto";
	else if (currentTask.value.MeetingType === MeetingTypes.External)
		return "Externo";
	return "Presencial";
});

const oldTitle = ref(document.title);

// Verifica mudanças na URL para tentar carregar a atividade
watch(() => route.fullPath, async () => {
	if (route.query.newTask?.toString())
		createActivity();
	else
		await loadTaskFromQuery();
});

watch(() => currentTask.value.MeetingRoom.meetingRoomId, () => {
	for (let meetingRoom of allMeetingRooms.value) {
		if (currentTask.value.MeetingRoom.meetingRoomId === meetingRoom.meetingRoomId) {
			currentTask.value.MeetingRoom.maxParticipant = meetingRoom.maxParticipant;
		}
	}
});

watch(() => isModalOpen.value, () => {
	if (isModalOpen.value)
		taskModal.value?.focus();
});

watch(() => isEditing.value, () => taskModal.value?.focus());

watch(() => registerHour.value, () => taskModal.value?.focus());

function blockEsc(e: KeyboardEvent) {
	if (e.key === "Escape" && (isCreating.value || isEditing.value || registerHour.value)) {
		e.preventDefault();
	}
	else if (e.key === "Escape") {
		closeTaskModal();
	}
}

function openDuplicateTaskModal() {
	modalClose();
	duplicateModal.value.open();
}

function closeDuplicateTaskModal() {
	duplicateModal.value.close();
	modalOpen();
}

function openArchiveTaskModal() {
	modalClose();
	archiveModal.value.open();
}

function closeArchiveTaskModal() {
	archiveModal.value.close();
	modalOpen();
}

function openUnarchiveTaskModal() {
	modalClose();
	unarchiveModal.value.open();
}

function closeUnarchiveTaskModal() {
	unarchiveModal.value.close();
	modalOpen();
}

function openDeleteRecordedTimeModal() {
	modalClose();
	deleteRecordedTimeModal.value.open();
}

function closeDeleteRecordedTimeModal() {
	modalOpen();
	deleteRecordedTimeModal.value.close();
}

function modalOpen() {
	isModalOpen.value = true;
	availableMeetingTimes.value = "";
	taskModal.value?.showModal();
}

function modalClose() {
	taskModal.value?.close();
	registerHour.value = false;
	isCreating.value = false;
	isEditing.value = false;
	isModalOpen.value = false;
}

/**
 * faz a criação de uma nova atividade
 */
function createActivity() {
	isCreating.value = true;
	currentTask.value = new TasksView();
	const user = new MemberInfo();
	user.Id = currentUser.value.UserId;
	user.Name = currentUser.value.Name;
	user.ImageUrl = getImageURL(currentUser.value.ImageId);
	document.title = "Nova atividade - OwnTime";
	currentTask.value.UsersInfo.push(user);
	loadFromLocalStorage();
	loadUsers();
	modalOpen();
}

const checkConflict = (busyTimes: any, currentTask: any) => {
	let longestBusyTime = null;
	let maxDuration = 0;

	for (let bt of busyTimes) {
		if (moment(currentTask.EndDate).isAfter(bt.Begin) && moment(currentTask.BeginDate).isBefore(bt.End)) {
			const duration = moment(bt.End).diff(moment(bt.Begin), "minutes");

			if (duration > maxDuration) {
				maxDuration = duration;
				longestBusyTime = bt;
			}
		}
	}

	return longestBusyTime;
};

const checkConflictForUserPictures = (userId: string) => {
	let users = allUsers.value.filter(user => user.Id === userId);

	for (let user of users) {
		const longestBusyTime = checkConflict(user.BusyTimes, currentTask.value);

		if (longestBusyTime) {
			switch (longestBusyTime.Type) {
				case BusyTimesTypes.MEETING:
					return "integrant-availability busy";
				case BusyTimesTypes.EXTERNAL:
					return "integrant-availability external";
				case BusyTimesTypes.MISSWORK:
				case BusyTimesTypes.ABSENCE:
					return "integrant-availability absence";
				case BusyTimesTypes.VACATION:
					return "integrant-availability vacation";
			}
		}
	}
	return "integrant-availability available";
};

type AlertItem = {
	class: string,
	message: string,
	iconType: string,
}

const alertItems = computed<AlertItem[]>(() => {
	let items = new Array<AlertItem>();

	let members = currentTask.value.UsersInfo.map(user => user.Id);
	let selectedUsers = allUsers.value.filter(selectedUser => members.includes(selectedUser.Id));

	for (let user of selectedUsers) {
		const longestBusyTime = checkConflict(user.BusyTimes, currentTask.value);

		if (longestBusyTime) {
			let begin = moment(longestBusyTime.Begin).locale("pt-br").format("LT");
			let end = moment(longestBusyTime.End).locale("pt-br").format("LT");
			let day = moment(longestBusyTime.End).locale("pt-br").format("ll");

			let dateBegin = moment(longestBusyTime.Begin).locale("pt-br").format("llll");
			let dateEnd = moment(longestBusyTime.End).locale("pt-br").format("llll");

			switch (longestBusyTime.Type) {
				case BusyTimesTypes.MEETING:
					items.push({
						class: "alert alert-warning",
						message: `O usuário ${user.Name} não está disponível neste horário pois estará em outra reunião no período entre ${begin}h e ${end}h do dia ${day}.`,
						iconType: "alert",
					});
					break;
				case BusyTimesTypes.EXTERNAL:
					items.push({
						class: "alert alert-external",
						message: `O usuário ${user.Name} não está disponível neste horário pois está em período externo entre ${dateBegin}h e ${dateEnd}h`,
						iconType: "flight-takeoff",
					});
					break;
				case BusyTimesTypes.MISSWORK:
				case BusyTimesTypes.ABSENCE:
					items.push({
						class: "alert alert-absence",
						message: `O usuário ${user.Name} não está disponível neste horário pois estará ausente no período entre ${dateBegin}h e ${dateEnd}h`,
						iconType: "quill-pen",
					});
					break;
				case BusyTimesTypes.VACATION:
					items.push({
						class: "alert alert-news",
						message: `O usuário ${user.Name} não está disponível neste horário pois está de férias no período entre ${dateBegin}h e ${dateEnd}h`,
						iconType: "empathize",
					});
					break;
			}
		}
	}

	return items;
});

let oldBeginDate = "";
let oldEndDate = "";

/**
 * requisita o carregamento de todos os usuários
 */
const loadUsers = async () => {
	try {
		const request = new GetUserMeetingAvailabillityRequest();
		let beginDate = currentTask.value.BeginDate
		let endDate = currentTask.value.EndDate
		
		if (currentTask.value.TaskId) {
			request.TaskId = currentTask.value.TaskId;
		}
		if (currentTask.value.TaskType === TaskTypes.Meeting) {
			if (moment(beginDate).isValid())
				request.Begin = moment(beginDate).locale("pt-br").format("YYYY-MM-DD");
			if (moment(endDate).isValid())
				request.End = moment(endDate).locale("pt-br").format("YYYY-MM-DD");
			if (request.End === "" || moment(request.End).isBefore(request.Begin)) {
				request.End = request.Begin;
			}
		}
		
		const response = await request.execute();

		allUsers.value = [];
		totalRecords.value = 0;

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			for (const record of response.Result.Records) {
				let userInfo = new UserInfoMeetings();
				userInfo.Id = record.UserId;
				userInfo.Name = record.Name;
				userInfo.ImageUrl = getImageURL(record.ImageId);

				for (const busyTimesInfo of record.BusyTimes) {
					let info = new BusyTimes();
					info.Type = busyTimesInfo.Type;
					info.Begin = busyTimesInfo.Begin;
					info.End = busyTimesInfo.End;

					userInfo.BusyTimes.push(info);
				}

				allUsers.value.push(userInfo);
			}

			totalRecords.value = response.Result.TotalRecords;
			oldBeginDate = request.Begin;
			oldEndDate = request.End;
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}
};

watchEffect(() => {
	let beginDate = moment(currentTask.value.BeginDate).format("YYYY-MM-DD");
	let endDate = moment(currentTask.value.EndDate).format("YYYY-MM-DD");

	if (beginDate !== oldBeginDate || endDate !== oldEndDate) {
		loadUsers();
	}
});

/**
 * faz o carregamento de todos os projetos
 */
const loadProjects = async () => {
	try {
		const request = new GetProjectsInfoRequest();
		const response = await request.execute();

		projects.value = [];

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			let _project = new Project();
			_project.Name = "Sem projeto";
			_project.ProjectId = null;
			projects.value.push(_project);

			for (const projectInfo of response.Result.Records) {
				let project = new Project();
				project.Name = projectInfo.ProjectName;
				project.ProjectId = projectInfo.ProjectId;
				projects.value.push(project);
			}
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}
};

/**
 * carrega todos os times
 */
const loadTeams = async () => {
	try {
		const request = new GetTeamsInfoRequest();
		const response = await request.execute();

		teams.value = [];

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			let _team = new Team();
			_team.Name = "Sem equipe";
			_team.TeamId = null;
			teams.value.push(_team);

			for (const teamInfo of response.Result.Records) {
				let team = new Team();
				team.Name = teamInfo.Name;
				team.TeamId = teamInfo.TeamId;
				teams.value.push(team);
			}
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}
};

/**
 * carrega as salas de reuniao registradas
 */
const loadMeetingRoomList = async () => {
	try {
		const request = new GetMeetingRoomListRequest();
		const response = await request.execute();

		allMeetingRooms.value = [];

		if (!response.Success) {
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			for (const meetingRoomInfo of response.Result.Records) {
				let meetingRoom = new MeetingRooms();
				meetingRoom.meetingRoomId = meetingRoomInfo.MeetingRoomId;
				meetingRoom.name = meetingRoomInfo.Name;
				meetingRoom.maxParticipant = meetingRoomInfo.MaxParticipant;

				allMeetingRooms.value.push(meetingRoom);
			}
		}
	}
	catch (err) {
		notify(DefaultWarn);
		console.warn(err);
	}
	finally {
		//
	}
};

/**
 * cria uma nova atividade
 */
const createTask = async () => {

	isLoading.value = true;
	loadStart();

	try {
		const request = new CreateTaskRequest();

		request.TeamId = currentTask.value.TeamId;
		request.ProjectId = currentTask.value.ProjectId;
		request.Points = pointsFrontToBack(currentTask.value.SliderPoints);
		request.Stage = currentTask.value.Stage;
		request.Name = currentTask.value.Name;
		request.Description = currentTask.value.Description;
		request.BeginDate = currentTask.value.BeginDate?.length === 16 ? currentTask.value.BeginDate + ":00" : currentTask.value.BeginDate;
		request.EndDate = currentTask.value.EndDate?.length === 16 ? currentTask.value.EndDate + ":00" : currentTask.value.EndDate;
		request.TaskType = currentTask.value.TaskType;

		if (currentTask.value.MeetingType) {
			request.MeetingType = currentTask.value.MeetingType;
			request.Place = currentTask.value.Place;
			request.MeetingRoomId = currentTask.value.MeetingRoom.meetingRoomId;
		}
		request.TaskMembers = currentTask.value.UsersInfo.map(user => user.Id);
		request.CheckListItems = currentTask.value.CheckListItems;
		request.IsPrivate = currentTask.value.IsPrivate;
		request.Effort = currentTask.value.Effort ? Number(currentTask.value.Effort) : null;
		request.Tags = currentTask.value.Tags.map(tag => tag.TagId);

		const response = await request.execute();

		if (response.Success) {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Atividade ${request.Name} criada.`,
			});

			await closeTaskModal();
			await loadTasks();

		}
		else {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoading.value = false;
		loadStop();
	}
};

/**
 * requisita a edição das alterações feitas na tarefa
 */
const editTask = async () => {

	isLoading.value = true;
	loadStart();

	try {
		const request = new EditTaskRequest();

		request.TaskId = currentTask.value.TaskId;
		request.TeamId = currentTask.value.TeamId;
		request.ProjectId = currentTask.value.ProjectId;
		request.Stage = currentTask.value.Stage;
		request.Points = pointsFrontToBack(currentTask.value.SliderPoints);
		request.Name = currentTask.value.Name;
		request.Description = currentTask.value.Description;
		request.BeginDate = currentTask.value.BeginDate?.length === 16 ? currentTask.value.BeginDate + ":00" : currentTask.value.BeginDate;
		request.EndDate = currentTask.value.EndDate?.length === 16 ? currentTask.value.EndDate + ":00" : currentTask.value.EndDate;
		request.TaskType = currentTask.value.TaskType;
		request.IsPrivate = currentTask.value.IsPrivate;
		request.Effort = currentTask.value.Effort ? Number(currentTask.value.Effort) : null;
		request.CantEditProject = currentTask.value.CanEditProject;
		request.CantEditTeam = currentTask.value.CantEditTeam;
		request.TaskMembers = currentTask.value.UsersInfo.map(user => user.Id);
		request.CheckListItems = currentTask.value.CheckListItems;
		request.Tags = currentTask.value.Tags.map(tag => tag.TagId);

		if (currentTask.value.MeetingType) {
			request.MeetingType = currentTask.value.MeetingType;
			request.Place = currentTask.value.Place;
			request.MeetingRoomId = currentTask.value.MeetingRoom.meetingRoomId;
		}

		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `A atividade ${currentTask.value.Name} foi editada.`,
			});
			cancelEdit();
			await Promise.all([loadTask(), loadTasks()]);
		}

	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		// loadTasks();
		loadStop();
		isLoading.value = false;
	}
};

/**
 * Adiciona parâmetro na rota para atualização das atividades, caso esteja na tela
 */
async function loadTasks() {
	if (route.name == RouteName.ACTIVITIES || route.name == RouteName.HOME) {
		await router.push({
			query: {
				loadTasks: "true",
			},
		});
	}
}

/**
 * Ativa o modo de edição da atividade
 */
function startEdit() {
	isEditing.value = true;
	currentTaskBackup.value = {...currentTask.value}; // Salva informações da atividade original
	currentTaskBackup.value.CheckListItems = currentTask.value.CheckListItems.slice();
}

/**
 * Desativa o modo de edição da atividade
 */
function cancelEdit() {
	isEditing.value = false;
	currentTask.value = {...currentTaskBackup.value}; // restaura informações da atividade original
}

/**
 * carrega e mostra atraves de um modal a atividade clicada pelo usuário
 */
const loadTask = async (id: string = currentTask.value.TaskId) => {
	isLoading.value = true;
	logOpen.value = false;
	modalOpen();

	try {
		loadStart();
		const request = new GetTaskRequest(id);
		const response = await request.execute();

		if (!response.Success) {
			await closeTaskModal();
			await loadTasks();
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {

			currentTask.value.TaskId = response.Result.TaskId;
			currentTask.value.Name = response.Result.TaskName;
			currentTask.value.Description = response.Result.Description;
			currentTask.value.TeamId = response.Result.TeamId;
			currentTask.value.TeamName = response.Result.TeamName;
			currentTask.value.CantEditTeam = response.Result.CantEditTeam;
			currentTask.value.ProjectId = response.Result.ProjectId;
			currentTask.value.ProjectName = response.Result.ProjectName;
			currentTask.value.CanEditProject = response.Result.CantEditProject;
			currentTask.value.Points = response.Result.Points;
			currentTask.value.TaskType = response.Result.TaskType;
			currentTask.value.CreatedDate = moment(response.Result.CreatedDate).locale("pt-br").format("llll");

			currentTask.value.CreatedBy.Id = response.Result.CreatedByUser.UserId;
			currentTask.value.CreatedBy.Name = response.Result.CreatedByUser.Name;
			currentTask.value.CreatedBy.ImageUrl = getImageURL(response.Result.CreatedByUser.ImageId);
			currentTask.value.CreatedBy.IsDeleted = response.Result.CreatedByUser.IsDeleted;

			currentTask.value.Place = response.Result.MeetingPlace;
			currentTask.value.MeetingType = response.Result.MeetingType;
			if (currentTask.value.MeetingType === MeetingTypes.Presential) {
				currentTask.value.MeetingType = MeetingTypes.MeetingRoom;
			}
			currentTask.value.Stage = response.Result.Stage;
			currentTask.value.BeginDate = response.Result.BeginDate;
			currentTask.value.EndDate = response.Result.EndDate;
			currentTask.value.CheckListItems = response.Result.CheckListItems;
			currentTask.value.CanEditTask = response.Result.CanEditTask;
			currentTask.value.CanAddHours = response.Result.CanAddHours;
			currentTask.value.IsPrivate = response.Result.IsPrivate;
			currentTask.value.Effort = response.Result.Effort;
			currentTask.value.Tags = response.Result.Tags as Tag[];

			currentTask.value.SliderPoints = pointsBackToFront(response.Result.Points);
			currentTask.value.MeetingRoom.meetingRoomId = response.Result.MeetingRoomId;
			currentTask.value.MeetingRoom.name = response.Result.MeetingRoomName;
			currentTask.value.MeetingRoom.maxParticipant = response.Result.MaxParticipant;
			currentTask.value.IsArchived = response.Result.IsDeleted;

			currentTask.value.EffortText = currentTask.value.Effort ? `${currentTask.value.Effort} horas` : "-";

			currentTask.value.UsersInfo = [];
			currentTask.value.TaskMembersConfirmed = [];
			currentTask.value.TaskMembersUnconfirmed = [];

			for (const memberInfo of response.Result.TaskMember) {
				const user = new MemberInfo();
				user.Id = memberInfo.UserId;
				user.Name = memberInfo.UserName;
				user.ImageUrl = getImageURL(memberInfo.ImageId);
				user.IsConfirmed = memberInfo.IsConfirmed;

				if (user.IsConfirmed)
					currentTask.value.TaskMembersConfirmed.push(user);
				else
					currentTask.value.TaskMembersUnconfirmed.push(user);

				currentTask.value.UsersInfo.push(user);
			}

			currentTask.value.CanDesconfirm = currentTask.value.TaskMembersConfirmed.filter(user => user.Id === userId).length > 0;
			currentTask.value.CanConfirm = currentTask.value.TaskMembersUnconfirmed.filter(user => user.Id === userId).length > 0;


			currentTask.value.CheckListItems = [];

			for (const taskChecklist of response.Result.CheckListItems) {
				let checklist = new CheckListItem();
				checklist.Record = taskChecklist.Record;
				checklist.Description = taskChecklist.Description;
				checklist.Done = taskChecklist.Done;
				currentTask.value.CheckListItems.push(checklist);
			}

			document.title = `${currentTask.value.Name} - OwnTime`;
			await Promise.all([loadTeams(), loadProjects(), loadUsers(), loadRecordedTime(), loadUserTotalRecordedTaskTime()]);
		}

	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		loadStop();
		isLoading.value = false;
	}
};

/**
 * deleta a atividade selecionada
 */
const archiveTask = async () => {

	isLoading.value = true;
	loadStart();

	try {
		const request = new DeleteTaskRequest(currentTask.value.TaskId);
		const response = await request.execute();

		if (response.Success) {
			archiveModal.value.close();
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `A atividade ${currentTask.value.Name} foi arquivada.`,
			});
			await loadTasks();
		}
		else {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		loadStop();
		isLoading.value = false;
	}
};

/**
 * desarquiva a atividade selecionada
 */
const unarchiveTask = async () => {
	isLoading.value = true;
	loadStart();

	try {
		const request = new UnarchiveTaskRequest(currentTask.value.TaskId);
		const response = await request.execute();

		if (response.Success) {
			unarchiveModal.value.close();
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `A atividade ${currentTask.value.Name} foi desarquivada.`,
			});
			await loadTasks();
		}
		else {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		loadStop();
		isLoading.value = false;
	}
};

/**
 * Verifica a query para carregar uma atividade
 */
async function loadTaskFromQuery() {
	loadFromLocalStorage();

	// Se houver um ID na URL, carregará a atividade

	if (route.query.taskIdFromQuery?.toString()) {
		if (checkGUID(route.query.taskIdFromQuery?.toString()))
			await loadTask(route.query.taskIdFromQuery.toString());
		else {
			notify(DefaultError("O ID da atividade é inválido"));
			await closeTaskModal();
		}
	}
	oldTitle.value = document.title;
}

/**
 * duplica a atividade selecionada
 */
function duplicateTask() {
	currentTask.value.Name = "Cópia de " + currentTask.value.Name;
	isCreating.value = true;
	closeDuplicateTaskModal();
}

/**
 * faz a confirmação de comparecimento na reunião no qual o usuário foi convidado
 * @param isConfirmed estado da confirmação
 */
const confirmPresenceMeeting = async (isConfirmed = true) => {
	isLoading.value = true;

	if (currentTask.value.TaskType !== TaskTypes.Meeting)
		return;

	try {
		loadStart();
		const request = new EditMeetingConfirmationRequest();
		request.TaskId = currentTask.value.TaskId;
		request.IsConfirmed = isConfirmed;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Você ${isConfirmed ? "confirmou" : "desconfirmou"} presença na reunião ${currentTask.value.Name}.`,
			});
		}

	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoading.value = false;
		await loadTask();
		loadStop();
	}
};

/**
 * Modifica o estado (Done) de um item do checklist da atividade
 * @param checklist Item do checklist a ser modificado o estado
 */
const changeStateChecklist = async (checklist: CheckListItem) => {
	try {
		const request = new EditStateChecklistRequest();
		request.Record = checklist.Record;
		request.IsDone = checklist.Done;

		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}
};

/**
 * faz a adição de horas trabalhadas na tarefa
 */
const createRecordTime = async () => {
	loadStart();
	try {
		const request = new CreateTaskRecordTimeRequest();
		request.TaskId = currentTask.value.TaskId;
		request.Date = newRegisterHours.value.date;
		request.Time = newRegisterHours.value.time;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Você registrou ${newRegisterHours.value.time} horas na atividade ${currentTask.value.Name}.`,
			});
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		await Promise.all([loadRecordedTime(), loadUserTotalRecordedTaskTime()]);
		loadStop();
	}
};

/**
 * carrega o total de horas registrada na atividade
 */
const loadUserTotalRecordedTaskTime = async () => {
	isLoadingTotalHours.value = true;
	try {
		const request = new GetUserHoursTaskRecordedTime(currentTask.value.TaskId);
		const response = await request.execute();

		taskRegisteredUserTotalHours.value = [];
		totalHoursRegistered.value = 0;

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			for (const record of response.Result.Records) {

				let userRecordedTime = new TaskRecordedTime();
				userRecordedTime.ImageUrl = getImageURL(record.ImageId);
				userRecordedTime.Time = record.TotalUserHours;
				userRecordedTime.Name = record.Name;

				taskRegisteredUserTotalHours.value.push(userRecordedTime);
			}
			totalHoursRegistered.value = response.Result.Records.length;
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoadingTotalHours.value = false;
	}
};

/**
 * carrega a lista de horas registradas na atividade
 */
const loadRecordedTime = async () => {
	isLoading.value = true;

	try {
		const request = new GetTaskRecordedTimeRequest(currentTask.value.TaskId);
		// Filtros removidos
		const response = await request.execute();

		taskRegisteredHours.value = [];
		hoursRegistered.value = 0;

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			for (const record of response.Result.Records) {

				let recordedTime = new TaskRecordedTime();
				recordedTime.Id = record.TaskRecordedTimeId;
				recordedTime.Name = record.Name;
				recordedTime.ImageUrl = getImageURL(record.ImageId);
				recordedTime.Date = moment(record.Date).locale("pt-br").format("L");
				recordedTime.Time = record.Time;
				recordedTime.CanDelete = record.CanDelete;

				taskRegisteredHours.value.push(recordedTime);
			}
			hoursRegistered.value = response.Result.TotalRecords;
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoading.value = false;
	}
};

/**
 * deleta o registro de horas selecionado da atividade
 * @param taskRecordedTime registro a ser deletado
 */
const deleteRecordedTime = async (taskRecordedTime: TaskRecordedTime) => {
	if (isLoading.value)
		return;

	isLoading.value = true;
	registerHour.value = true;

	loadStart();

	try {
		const request = new DeleteTaskRecordedTimeRequest(taskRecordedTime.Id);
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			closeDeleteRecordedTimeModal();
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Você excluiu suas ${taskRecordedTime.Time} da atividade ${currentTask.value.Name}.`,
			});
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		loadStop();
		await Promise.all([loadRecordedTime(), loadUserTotalRecordedTaskTime()]);
		isLoading.value = false;
		isLoadingTotalHours.value = false;
	}
};

/**
 * fecha todos os seletores abertos
 */
function closeSelectors() {
	addMemberOpen.value = false;
	addTagOpen.value = false;
}

/**
 * abre ou fecha o seletor de tags
 */
function openTagSelector() {
	addTagOpen.value = !addTagOpen.value;
	addMemberOpen.value = false;
}

/**
 * adiciona uma tag na atividade
 * @param tag item a ser adicionado na atividade
 */
function addTag(tag: Tag) {
	if (currentTask.value.Tags.some(t => t.TagId == tag.TagId))
		currentTask.value.Tags = currentTask.value.Tags.filter(t => t.TagId != tag.TagId);
	else
		currentTask.value.Tags.push(tag);
}

/**
 * busca um usuário se está disponivel para adicionar na lista de membros da tarefa
 */
function setAvailableUsers() {
	userAvailableList.value = allUsers.value.filter((user: MemberInfo) => {
		let member = currentTask.value.UsersInfo.filter((taskMember: MemberInfo) => taskMember.Id == user.Id);
		return member.length == 0;
	});
}

/**
 * abre ou fecha o seletor de usuários
 */
function openUserSelector() {
	addMemberOpen.value = !addMemberOpen.value;
	addTagOpen.value = false;
	setAvailableUsers();
}

/**
 * faz a exclusão de um membro da tarefa
 * @param idx indice onde cada indice é o usuário selecionado
 */
function excludeTaskMember(idx: number) {
	let excluded = currentTask.value.UsersInfo.splice(idx, 1);
	userAvailableList.value.push(excluded[0]);
	loadUsers();
}

/**
 * adiciona um usuário para membro da atividade
 * @param user usuário desejado
 */
function addMember(user: MemberInfo) {
	currentTask.value.UsersInfo.push(user);
	setAvailableUsers();
}

/**
 * adiciona todos os usuários como membros da atividade
 */
function addAllMembers() {
	currentTask.value.UsersInfo = [...currentTask.value.UsersInfo, ...userAvailableList.value];
	addMemberOpen.value = false;
	setAvailableUsers();
}

function onMarkdownTextUpdate(newValue: string) {
	currentTask.value.Description = newValue;
}

function setChecklistItemHovering(event: DragEvent, idx: number) {
	if (event.target instanceof Element && event.target.classList.contains("dragging")) {
		checklistHoveringIdx.value = idx;
	}
}

function removeChecklistItemHovering(event: DragEvent) {
	if (event.target instanceof Element && event.target.id != "") {
		checklistHoveringIdx.value = -1;
	}
}

function startDrag(event: DragEvent, idx: number) {
	currentChecklistIdx.value = idx;

	if (event.dataTransfer) {
		event.dataTransfer.dropEffect = "move";
		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData("checklistIdx", idx.toString());
		isDragging.value = true;
	}
}

async function onDrop(event: DragEvent, idx: number) {
	isDragging.value = false;
	const checklistIdx = event.dataTransfer?.getData("checklistIdx");
	checklistHoveringIdx.value = idx;

	if (!checklistIdx || parseInt(checklistIdx) == -1 || parseInt(checklistIdx) != currentChecklistIdx.value)
		return;

	try {
		move(currentTask.value.CheckListItems, currentChecklistIdx.value, checklistHoveringIdx.value);
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		currentChecklistIdx.value = -1;
		checklistHoveringIdx.value = -1;
	}
}

function move(array: Array<any>, from: number, to: number) {
	if (to === from) return array;

	let target = array[from];
	let increment = to < from ? -1 : 1;

	for (let k = from; k != to; k += increment) {
		array[k] = array[k + increment];
	}
	array[to] = target;
	return array;
}

/**
 * deleta um item do checklist da atividade
 * @param idx indice que será deletado
 */
function excludeChecklistItem(idx: number) {
	currentTask.value.CheckListItems.splice(idx, 1);
}

/**
 * adiciona um novo item ao checklist da atividade
 */
function newChecklistItem() {
	currentTask.value.CheckListItems.push(new CheckListItem());
}

/**
 * deleta o tempo adicionado registrado na tarefa
 * @param recordedTime tempo que o usuário trabalhou e adiciona na tarefa
 */
function getRecordedTimeToDelete(recordedTime: TaskRecordedTime) {
	recordedTimeToDelete.value = recordedTime;
	openDeleteRecordedTimeModal();
}

/**
 * Fecha o modal e remove o ID da URL
 */
const closeTaskModal = async () => {
	modalClose();
	await router.replace({
		query: undefined,
	});
	document.title = oldTitle.value;
};

const availableMeetingTimes = ref("");

watchEffect(() => {
	if (currentTask.value.MeetingRoom.meetingRoomId != null && currentTask.value.BeginDate != "" && (isEditing.value || isCreating.value)) {
		loadAvailableMeetingTimes();
	}
});

function simpleFormatHour(date: string): string {
	return moment(date).locale("pt-br").format("LT");
}

async function loadAvailableMeetingTimes() {
	if (!currentTask.value.MeetingRoom.meetingRoomId)
		return;

	try {
		const request = new GetTaskAvailableMeetingTimes();
		request.MeetingRoomId = currentTask.value.MeetingRoom.meetingRoomId;
		request.MeetingDate = currentTask.value.BeginDate;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			availableMeetingTimes.value = "";
			if (response.Result.Records.length === 0)
				availableMeetingTimes.value = "Não há horários disponíveis para a data e local selecionados.";

			else if (response.Result.Records.length === 1 && simpleFormatHour(response.Result.Records[0].Begin) === simpleFormatHour(response.Result.Records[0].End))
				availableMeetingTimes.value = "O dia inteiro está disponível para a data e local selecionados.";

			else {
				availableMeetingTimes.value += "Os horários disponíves para a data e local selecionados são:\n";

				let periods: string[] = [];

				for (const period of response.Result.Records) {
					periods.push(`${simpleFormatHour(period.Begin)} às ${simpleFormatHour(period.End)}`);
				}

				availableMeetingTimes.value += periods.join(", ") + ".";
			}
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}

}

/**
 * faz o carregamento dos items que estão armazenados no local storage
 */
function loadFromLocalStorage() {
	isCompact.value = JSON.parse(localStorage.getItem("isCompact") ?? "false") as boolean;
	showTags.value = JSON.parse(localStorage.getItem("showTags") ?? "false") as boolean;
}

onMounted(async () => {
	await loadTaskFromQuery();

	await Promise.all([
		loadProjects(),
		loadUsers(),
		loadTeams(),
		loadMeetingRoomList(),
	]);
});

</script>

<style scoped lang="scss">

@import "@/styles/Variables.scss";

dialog::backdrop {
	background-color: hsla(0, 0%, 0%, 0.6);
}

.modal-background {

	width: 100%;
	height: 100%;
	background: none;
	border: none;
	transition: transform 0.3s ease-out, opacity 0.2s linear;
	transform: translateY(-50px);
	opacity: 0;
	max-width: unset;
	display: block;
	pointer-events: none;
	max-height: unset;
	position: absolute;
	top: 0;
	scrollbar-gutter: stable;

	&:focus-visible {
		outline: none;
	}

	.modal-content {
		max-width: 500px;
		width: 100%;
		border: none;
		background: #fff;
		margin: 3dvh auto;
		box-shadow: rgba(0, 0, 0, .1) 0 1px 15px;
		flex-direction: column;
		border-radius: 20px;
		display: flex;
		pointer-events: none;
		top: 0;

		&.modal-lg {
			max-width: 800px;
		}

		&.modal-xl {
			max-width: 1140px;
		}

		@media(max-width: 575px) {
			width: 95%;
			margin: 1dvh auto;
		}
	}

	&[open] {

		pointer-events: all;
		opacity: 1;
		transform: translateY(0);
		position: unset;

		.modal-content {
			pointer-events: all;
		}
	}

	.modal-header {
		gap: 0.5rem;
		border: none;

		//position: sticky;
		//top: 0;
		//z-index: 1;
		//background-color: $ot-dark-background-secondary-full;

		.task-name {
			font-size: 23px;
			flex-grow: 1;
			background-color: $ot-background-secondary-full;
			border-color: transparent;
			word-break: normal;

			&:focus-visible {
				outline: none;
				border-radius: 1rem;
				box-shadow: 0 0 0 0.25rem rgb(146 161 196 / 50%);
			}
		}

		p.task-name {
			margin: 0
		}

		#duplicate {
			opacity: 0.75;

			&:hover {
				opacity: 1;
			}
		}
	}

	.modal-body {
		padding: 0.5rem 1.5rem;

		.form-label {
			font-size: 1rem;
		}

		.alert-messages {
			overflow-y: auto;
			max-height: 180px;
			margin-bottom: .75rem;

			.alert {
				margin-bottom: .5rem;
				font-size: .9rem;
				padding: .5rem;

				&.alert-warning {
					color: #664d03;
					background-color: rgba(255, 243, 205, 0.57);
					border-color: #ffecb5;
				}

				&.alert-absence {
					color: hsl(212, 62%, 56%);
					background-color: hsl(200deg 80% 60% / 15%);
					border-color: hsl(212, 62%, 56%);
				}

				&.alert-external {
					color: hsl(211, 72%, 46%);
					background-color: hsl(200deg 80% 60% / 15%);
					border-color: hsl(211, 72%, 46%);
				}
			}
		}

		.integrant-pics-container {
			position: relative;

			#integrant-pics {
				transition: opacity ease-in-out 0.2s;

			}

			.integrant-availability, .members {
				border-radius: 100%;
				opacity: 1;
				font-size: 12px;
				display: flex;
				position: absolute;
				justify-content: right;
				padding: 5px;
				inset: 0;
				cursor: pointer;
				margin: 0;
				background-color: rgba(209, 209, 209, 0.75);
				transition: opacity ease-in-out 0.2s;

				&.busy {
					color: hsl(44, 100%, 49%);
					border: 2px solid hsl(44, 100%, 49%);
				}

				&.external {
					color: #2f8efb;
					border: 2px solid #2f8efb;
				}

				&.vacation {
					color: $ot-highlight-title;
					border: 2px solid $ot-highlight-title;
				}

				&.absence {
					color: hsl(212, 62%, 56%);
					border: 2px solid hsl(212, 62%, 56%);
				}

				&.available {
					display: none;
				}
			}


			.integrant-delete {
				border-radius: 100%;
				transition: opacity ease-in-out 0.2s;
				opacity: 0;
				font-size: 22px;
				color: $ot-danger;
				display: flex;
				justify-content: space-around;
				align-items: center;
				position: absolute;
				inset: 0;
				cursor: pointer;
			}

			&:hover {
				#integrant-pics,
				.integrant-availability {
					opacity: 0.15;
				}

				.busy,
				.absence,
				.external {
					opacity: 0;
				}

				.integrant-delete {
					opacity: 1;
				}
			}
		}

		#description {
			white-space: pre-wrap;

			&[readonly] {
				box-shadow: none;
			}
		}

		.btn-round {
			min-width: unset;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 100%;
			transition: transform ease-in 0.1s;

			i {
				margin: 0
			}

			&.opened {
				transition: transform ease-out 0.1s;
				transform: rotate(45deg);
			}

			&.btn-sm {
				width: 2rem;
				height: 2rem;
			}
		}

		.members, .task-tags {
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			position: relative;
			margin-bottom: 0.5rem;
			align-items: center;

			.add-member,
			.add-tag {
				display: flex;
				justify-content: center;
			}

			.tag-label-container {
				display: flex;
			}
		}

		.external-link {
			text-decoration: none;
			color: inherit;

			&:hover {
				color: $ot-highlight-active
			}
		}

		p {
			margin-bottom: 0.5rem;
		}

		.task-item {
			margin-top: 1.5rem;
			display: grid;
			gap: 3rem;
			grid-auto-flow: column;
			grid-template-columns: repeat(3, minmax(0, 1fr));

			.slider {
				width: 100%;
			}

			.form-range {
				&::-webkit-slider-runnable-track {
					background: linear-gradient(90deg, rgba(180, 180, 187, 1) 0%, rgba(255, 157, 45, 1) 50%, rgba(255, 79, 79, 1) 100%);
				}

				&::-moz-range-track {
					background: linear-gradient(90deg, rgba(180, 180, 187, 1) 0%, rgba(255, 157, 45, 1) 50%, rgba(255, 79, 79, 1) 100%);
				}

				&::-moz-range-progress {
					background: linear-gradient(90deg, rgba(180, 180, 187, 1) 0%, rgba(255, 157, 45, 1) 50%, rgba(255, 79, 79, 1) 100%);
				}

				&::-webkit-slider-thumb {
					box-shadow: 0 0 0 3px #FFFFFF30 inset;
					background-color: $ot-text-secondary;
				}

				&::-moz-range-thumb {
					border: 3px inset #FFFFFF20;
					background-color: $ot-text-secondary;
					box-sizing: border-box;
				}
			}

			datalist {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;

				option {
					font-size: 0.8rem;
					width: 15px;
					text-align: center;
				}
			}

			#link {
				word-break: break-all;
				display: block;
			}
		}

		.text-area-container {

			.characters {
				display: block;
				text-align: end;
				font-size: 0.8rem;
				opacity: 0.8;

				&.over-limit {
					color: $ot-danger;
					font-weight: 600;
				}
			}
		}

		.checklist {
			display: grid;
			gap: 0.5rem;
			margin-top: 1rem;

			.btn {
				border-radius: 0.25rem;
				max-width: 100px;
			}

			.editting {
				display: flex;
				flex-wrap: wrap;
				margin-top: 0;
				gap: 0.5rem;
				align-items: center;

				.form-check-input {
					margin-top: 0;
				}

				.form-control {
					flex-basis: content;
					flex-grow: 1;
				}

				.ri-draggable {
					cursor: move
				}
			}

			.form-check-label {
				font-size: 0.9rem;
			}

			.dragging {
				z-index: 10;

				*:not(.form-check):not(.hover-container) {
					pointer-events: none;
				}

				.dragging-me {
					opacity: 0.25;
				}
			}

			.hover-container {
				.hover {
					border-radius: 0.5rem;
					background-color: $ot-highlight-active;
					min-height: 0;
					opacity: 0;
					transition: all ease-in-out 0.05s;

					&.show {
						min-height: 2px;
						opacity: 1;
					}
				}

				&:has(.show) {
					padding-bottom: 0.75rem;
				}
			}
		}

		.exclude {
			color: $ot-text-primary;

			&:hover {
				color: $ot-danger
			}
		}

		.add-hours {
			background-color: $ot-background-secondary;
			padding: 0.5rem 0.75rem;
			border-radius: 100px;
			margin-bottom: 2.5rem;
			display: grid;
			gap: 1rem;
			grid-auto-flow: column;
			align-items: baseline;
		}

		.set-period {
			margin-bottom: 1rem;
			display: grid;
			gap: 1rem;
			grid-auto-flow: column;
			justify-content: start;
			align-items: baseline;
		}

		@media (max-width: 991px) {
			.task-item {
				grid-auto-flow: row;
				grid-template-columns: unset;
				margin-top: 1rem;
				gap: 1rem;
			}
			.add-hours {
				grid-auto-flow: row;
				justify-content: stretch;
				border-radius: 2rem;
			}
			.set-period {
				grid-auto-flow: row;
				justify-content: stretch;
				gap: 0.5rem;

				p {
					margin-bottom: 0;
				}
			}
			.table-hours * {
				white-space: nowrap;
			}
		}
	}

	.modal-footer {
		border: none;

		//position: sticky;
		//bottom: 0;
		//z-index: 1;
		//background-color: $ot-dark-background-secondary-full;

		.btn-dark,
		.btn-light {
			margin-right: auto;
		}
	}

	@media (max-width: 425px) {
		.modal-footer {
			.btn {
				width: 100%;
			}
		}
	}

	@media (max-width: 991px) {
		.modal-footer {
			.btn-log {
				order: 3;
				width: 100%;
			}
		}
	}

	.priority-flags-modal {
		margin-top: -3.5rem;
		margin-right: 1rem;
		width: 55px;
		height: 55px;
	}

	th, td {
		border: none
	}

	.table-hours {
		border: none;

		td {
			padding: 0.3rem 0.5rem;
		}

		.img-user {
			margin-right: 0.5rem;
		}

		&:last-child,
		&:last-child table {
			margin-bottom: 0;
		}
	}
}

.user-available {
	display: none;
}

body:has(#app > main.dark) {
	.modal-content {
		.modal-header {
			.task-name {
				background-color: $ot-dark-background-secondary-full;
			}
		}

		.modal-body {
			.task-item .form-range {
				&::-webkit-slider-thumb {
					box-shadow: 0 0 0 3px #FFFFFF60 inset;
					background-color: $ot-dark-text-secondary;
				}

				&::-moz-range-thumb {
					background-color: $ot-dark-text-secondary;
				}
			}

			.integrant-availability {
				background-color: rgba(55, 53, 49, 0.75);

				&.busy {
					border: 1.75px solid hsl(44, 100%, 49%);
				}

				&.external {
					border: 1.75px solid #2f8efb;
				}

				&.vacation {
					border: 1.75px solid $ot-highlight-title;
				}

				&.absence {
					border: 1.75px solid hsl(212, 62%, 56%);
				}

				&.available {
					display: none;
				}
			}

			.alert {
				//&.alert-warning {
				//	color: hsl(44, 100%, 49%);
				//	background-color: hsl(44deg 80% 60% / 15%);
				//	border-color: hsl(44, 100%, 49%);
				//}
				&.alert-external {
					color: hsl(212, 100%, 63%);
					background-color: hsl(200deg 80% 60% / 15%);
					border-color: hsl(212, 100%, 63%);
				}
			}

			.exclude {
				color: $ot-dark-text-primary;

				&:hover {
					color: $ot-danger
				}
			}
		}
	}
}

</style>