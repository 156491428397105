import { Module } from "vuex";
import { IRootState } from "@/store";
import { IUpdateState, state } from "./state";
import { getters } from "./getters";
import { mutations } from "@/store/modules/updates/mutations";

// Module
const update: Module<IUpdateState, IRootState> = {
	state,
	getters,
	mutations,
};

export default update;