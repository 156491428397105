import Api from "@/api";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import { MeetingTypes, TaskTypes } from "@/api/requests/task/EditTaskRequest";
import { StageTypes } from "@/api/requests/task/GetTaskRequest";
import { TagInfo } from "@/api/requests/tag/GetTagsRequest";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";

export default class GetTaskLogsRequest {

	TaskId = "";
	Record = 0;

	async execute(): Promise<DefaultBooleanResponseResult<TaskLogs>> {
		const response = await Api.get("/task/logs", {
			params: this,
		});
		return response.data;
	}
}

interface TaskLogs {
	Logs: Logs[];
}

export interface Logs {
	Record: number;
	TaskLogId: string;
	CreatedByUser: UsersInfo;
	CreatedBySprint: SprintInfo;
	CreatedDate: string;
	LogEntries: LogEntries[];
	LogCommentaries: LogCommentaries[];
}

export interface UsersInfo {
	UserId: string;
	ImageId: string;
	Name: string;
	IsDeleted: boolean;
}

export interface SprintInfo {
	SprintId: string;
	Name: string;
}
export interface LogEntries {
	TaskLogId: string;
	MessageType: number;
	OldValue: string;
	NewValue: string;
	OldValueId: string;
	NewValueId: string;
}

export interface LogCommentaries {
	Record: number;
	TaskLogId: string;
	CreatedByUser: UsersInfo;
	Commentary: string;
	CreatedDate: string;
	CanDelete: boolean;
	IsDeleted: boolean;
}

