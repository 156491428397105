import { createStore } from "vuex";
import auth from './modules/auth'
import { IAuthState } from "@/store/modules/auth/state";
import update from "@/store/modules/updates";
import { IUpdateState } from "@/store/modules/updates/state";

export interface IRootState {
    authState: IAuthState;
	updateState: IUpdateState;
}

export default createStore({
	modules: {
		auth,
		update
	},
});
