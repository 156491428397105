import Api from "@/api/index";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";

export default class GetUserCardsRecordsRequest {
	Page = 1;
	ItensPerPage = 15;

	constructor(page = 1, results = 15) {
		this.Page = page;
		this.ItensPerPage = results;
	}

	BeginDate: string | null = "";
	EndDate: string | null = "";
	Type: number | null = null;

	async execute(): Promise<DefaultListResponse<CardRecordInfo>> {
		const response = await Api.get("/card-records", {
			params: this,
		});
		return response.data;
	}
}

interface CardRecordInfo {
	CardRecordId: string;
	Date: string;
	RecordType: CardRecordType;
	DelayStatus: DelayStatus;
	Justification: string;
	UserRecordSolicitationId: string | null;
	UserHistoricRegister: UserHistoricRegister[];
}

export interface UserHistoricRegister {
	PreviousTimeAdjusted: string,
	CreatedDate: string,
	ReplyTime: string,
	NewTime: string,
	ReplyByUserId: string,
	ReplyByUserName: string,
	Status: number,
	InEffect: boolean
}

export enum CardRecordType {
	Entrada = 1,
	Saida = 2,
}

export enum AdjustmentStatus {
	Pendente = 1,
	Ajustado = 2,
	Recusado = 3,
	Cancelado = 4,
}

export enum DelayStatus {
	SemAtraso = 1,
	AtrasoSemJustificativa = 2,
	AtrasoJustificado = 3,
}