import { MutationTree } from "vuex";
import { IUpdateState } from "@/store/modules/updates/state";
export enum UpdateMutation {
	CLOSE_POPUP = "UPDATE/CLOSE_POPUP",
}
export const mutations: MutationTree<IUpdateState> = {
	[UpdateMutation.CLOSE_POPUP](state: IUpdateState) {
		state.oldVersion = state.newsArray[0].version;
		localStorage.setItem('version', state.newsArray[0].version)
	},
};
