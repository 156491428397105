import Api from "@/api";
import { JourneyType, UserType } from "@/models/Admin/Users";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";

export default class GetUserMeetingAvailabillityRequest {

	TaskId: string | null = "";
	Begin = "";
	End = "";

	async execute(): Promise<DefaultListResponse<UserMeetingsInfo>> {
		const response = await Api.get("/gerenciamento/usuarios/meeting-availability", {
			params: this,
		});
		return response.data;
	}
}

export interface UserMeetingsInfo {
	UserId: string,
	ImageId: string,
	Name: string,
	BusyTimes: BusyTimes[],
}

interface BusyTimes {
	Type: number,
	Begin: string,
	End: string,
}

