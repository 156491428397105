import { MeetingTypes, TaskTypes } from "@/api/requests/task/EditTaskRequest";
import { StageTypes } from "@/api/requests/task/GetTaskRequest";
import { CommentLog, LogEntry, TaskLog } from "@/models/Components/TaskLog";
import { UserInfo, UserInfoMeetings } from "@/models/User/UserInfo";
import { MeetingRooms } from "@/models/Admin/MeetingRooms";
import { TaskStatus } from "@/api/requests/task/GetNearTasksRequest";
import { Tag } from "@/models/Tasks/Tag";
import { LogEntries } from "@/api/requests/task/GetTaskLogsRequest";

export class TasksView {
	TaskId = "";
	Name = "";
	Description = "";
	CreatedBy: UserInfo = new UserInfo();
	CreatedDate = "";
	CreatedDateShort = "";
	ProjectId: string | null = null;
	ProjectName = "";
	ProjectColor = "";
	TeamId: string | null = null;
	TeamName = "";
	UsersInfo: MemberInfo[] = [];
	BeginDate = "";
	EndDate = "";
	EndDateSmall = "";
	IsLate = false;
	Points = 0;
	SliderPoints = 0;
	Priority = "";
	Stage: StageTypes = 0;
	CheckListItems: CheckListItem[] = [];
	Place = "";
	MeetingType: MeetingTypes = MeetingTypes.Presential;
	TaskType: TaskTypes = TaskTypes.Task;
	TaskMembers: MemberInfo[] = [];
	TaskMembersConfirmed: MemberInfo[] = [];
	TaskMembersUnconfirmed: MemberInfo[] = [];
	CantEditTeam = false;
	CanEditProject = false;
	CanEditTask = false;
	CanAddHours = false;
	CanConfirm = false;
	CanDesconfirm = false;
	IsPrivate = false;
	IsArchived = false;
	Effort: number | null = null;
	EffortText = "";
	Tags: Tag[] = [];
	
	
	//Near Tasks - Se vai iniciar ou encerrar logo
	Status: TaskStatus = TaskStatus.AIniciar;
	StatusText = "";

	//Last tasks - Data do último lançamento de horas
	LastTimeRecord = "";
	MeetingRoom: MeetingRooms = new MeetingRooms();
}

export class MemberInfo extends UserInfoMeetings {
	IsConfirmed = false;
}

export class CheckListItem {
	Record: number | null = null;
	Description = "";
	Done = false;
}

export enum View {
	KANBAN = 0,
	TABLE = 1,
	CALENDAR = 2,
}

export function pointsFrontToBack(n: number) {
	// let fibo = 2.078087 * Math.log(n) + 1.672276;
	// return Math.round(fibo);
	switch (n) {
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 5;
		case 5:
			return 8;
		case 6:
			return 13;
		default:
			return 1;
	}
}

export function pointsBackToFront(n: number) {
	switch (n) {
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 5:
			return 4;
		case 8:
			return 5;
		case 13:
			return 6;
		default:
			return 0;
	}
}