<template>
	<div id="sidebar-container">
		<button class="btn btn-small btn-primary burger-sidebar" @click="toggleSidebar" aria-label="Abrir menu lateral">
			<OwnIcon type="menu" large />
		</button>
		<aside id="sidebar" :class="{open : isMenuOpen}">

			<nav class="nav">
				<section class="navbar-header">
					<router-link class="svg-with-link" :to="{ name: RouteName.HOME }">
						<svg :height="isMenuOpen ? 80 : 50" :viewBox="isMenuOpen ? '0 0 413 207' : '0 0 150 207'" class="p-0 logo" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path opacity="0.75" d="M107.165 94C98.0705 109.752 93.5233 117.628 87.5867 120.271C82.4086 122.576 76.4954 122.576 71.3173 120.271C65.3807 117.628 60.8335 109.752 51.7392 94L42.2129 77.5C33.1186 61.7481 28.5714 53.8722 29.2507 47.4094C29.8432 41.7723 32.7998 36.6513 37.3854 33.3197C42.6427 29.5 51.7371 29.5 69.9257 29.5L88.9783 29.5C107.167 29.5 116.261 29.5 121.519 33.3197C126.104 36.6513 129.061 41.7723 129.653 47.4094C130.333 53.8722 125.785 61.7481 116.691 77.5L107.165 94Z" fill="url(#paint1)" />
							<path opacity="0.75" d="M52.1911 113.106C61.2855 97.3546 65.8326 89.4787 71.7692 86.8355C76.9474 84.5301 82.8606 84.5301 88.0387 86.8355C93.9753 89.4787 98.5224 97.3546 107.617 113.106L117.143 129.606C126.237 145.358 130.785 153.234 130.105 159.697C129.513 165.334 126.556 170.455 121.971 173.787C116.713 177.606 107.619 177.606 89.4302 177.606L70.3777 177.606C52.189 177.606 43.0947 177.606 37.8373 173.787C33.2517 170.455 30.2951 165.334 29.7026 159.697C29.0234 153.234 33.5705 145.358 42.6649 129.606L52.1911 113.106Z" fill="url(#paint2)" />
							<g v-if="isMenuOpen" fill="black">
								<path class="fill-letter" d="M192.416 104.819C192.416 108.737 191.682 112.092 190.214 114.886C188.759 117.668 186.77 119.798 184.249 121.278C181.739 122.758 178.892 123.498 175.708 123.498C172.524 123.498 169.671 122.758 167.15 121.278C164.64 119.787 162.652 117.65 161.184 114.868C159.728 112.075 159 108.725 159 104.819C159 100.9 159.728 97.5505 161.184 94.7688C162.652 91.9753 164.64 89.8387 167.15 88.359C169.671 86.8794 172.524 86.1396 175.708 86.1396C178.892 86.1396 181.739 86.8794 184.249 88.359C186.77 89.8387 188.759 91.9753 190.214 94.7688C191.682 97.5505 192.416 100.9 192.416 104.819ZM185.793 104.819C185.793 102.06 185.361 99.7345 184.497 97.8406C183.645 95.9348 182.461 94.4966 180.946 93.5259C179.431 92.5434 177.685 92.0522 175.708 92.0522C173.731 92.0522 171.985 92.5434 170.47 93.5259C168.955 94.4966 167.765 95.9348 166.901 97.8406C166.049 99.7345 165.623 102.06 165.623 104.819C165.623 107.577 166.049 109.908 166.901 111.814C167.765 113.708 168.955 115.146 170.47 116.129C171.985 117.1 173.731 117.585 175.708 117.585C177.685 117.585 179.431 117.1 180.946 116.129C182.461 115.146 183.645 113.708 184.497 111.814C185.361 109.908 185.793 107.577 185.793 104.819Z" />
								<path class="fill-letter" d="M203.988 123L196.282 95.7276H202.834L207.628 114.904H207.877L212.778 95.7276H219.258L224.159 114.797H224.425L229.148 95.7276H235.718L227.994 123H221.3L216.187 104.57H215.814L210.7 123H203.988Z" />
								<path class="fill-letter" d="M246.633 107.02V123H240.206V95.7276H246.349V100.362H246.669C247.296 98.8349 248.296 97.6216 249.669 96.7219C251.054 95.8223 252.765 95.3725 254.801 95.3725C256.683 95.3725 258.322 95.775 259.719 96.5799C261.128 97.3848 262.217 98.5508 262.986 100.078C263.767 101.605 264.152 103.457 264.14 105.635V123H257.713V106.63C257.713 104.807 257.239 103.38 256.292 102.35C255.357 101.321 254.061 100.806 252.404 100.806C251.279 100.806 250.279 101.054 249.403 101.551C248.539 102.037 247.858 102.741 247.361 103.664C246.876 104.588 246.633 105.706 246.633 107.02Z" />
								<path class="fill-letter" d="M267.066 92.1587V86.6367H296.078V92.1587H284.839V123H278.305V92.1587H267.066Z" />
								<path class="fill-letter" d="M301.387 123V95.7276H307.815V123H301.387ZM304.619 91.8569C303.601 91.8569 302.725 91.5195 301.991 90.8448C301.257 90.1583 300.89 89.3356 300.89 88.3768C300.89 87.4061 301.257 86.5835 301.991 85.9087C302.725 85.2222 303.601 84.8789 304.619 84.8789C305.649 84.8789 306.525 85.2222 307.247 85.9087C307.981 86.5835 308.347 87.4061 308.347 88.3768C308.347 89.3356 307.981 90.1583 307.247 90.8448C306.525 91.5195 305.649 91.8569 304.619 91.8569Z" />
								<path class="fill-letter" d="M314.424 123V95.7276H320.568V100.362H320.887C321.456 98.7994 322.397 97.5801 323.711 96.7042C325.025 95.8164 326.593 95.3725 328.416 95.3725C330.262 95.3725 331.819 95.8223 333.086 96.7219C334.364 97.6097 335.264 98.823 335.784 100.362H336.069C336.672 98.8467 337.69 97.6393 339.123 96.7397C340.567 95.8282 342.277 95.3725 344.254 95.3725C346.763 95.3725 348.811 96.1656 350.397 97.7518C351.984 99.338 352.777 101.652 352.777 104.694V123H346.331V105.689C346.331 103.996 345.882 102.759 344.982 101.978C344.082 101.185 342.981 100.788 341.679 100.788C340.129 100.788 338.915 101.273 338.039 102.244C337.175 103.203 336.743 104.452 336.743 105.99V123H330.44V105.422C330.44 104.014 330.014 102.889 329.162 102.049C328.321 101.208 327.22 100.788 325.859 100.788C324.936 100.788 324.095 101.025 323.338 101.498C322.58 101.96 321.976 102.617 321.527 103.469C321.077 104.31 320.852 105.292 320.852 106.417V123H314.424Z" />
								<path class="fill-letter" d="M371.371 123.533C368.637 123.533 366.275 122.965 364.287 121.828C362.31 120.68 360.789 119.059 359.724 116.963C358.658 114.856 358.126 112.377 358.126 109.524C358.126 106.718 358.658 104.256 359.724 102.137C360.801 100.007 362.304 98.3496 364.233 97.1658C366.163 95.9703 368.43 95.3725 371.034 95.3725C372.715 95.3725 374.301 95.6448 375.792 96.1893C377.296 96.7219 378.622 97.5505 379.77 98.6751C380.93 99.7996 381.841 101.232 382.504 102.972C383.167 104.7 383.498 106.76 383.498 109.151V111.122H361.144V106.789H377.337C377.325 105.558 377.059 104.463 376.538 103.505C376.017 102.534 375.289 101.77 374.354 101.214C373.431 100.658 372.354 100.38 371.123 100.38C369.809 100.38 368.655 100.699 367.66 101.338C366.666 101.966 365.891 102.794 365.334 103.824C364.79 104.842 364.512 105.961 364.5 107.18V110.962C364.5 112.548 364.79 113.909 365.37 115.046C365.95 116.17 366.761 117.034 367.802 117.638C368.844 118.23 370.063 118.526 371.46 118.526C372.395 118.526 373.242 118.396 373.999 118.135C374.757 117.863 375.414 117.467 375.97 116.946C376.526 116.425 376.947 115.78 377.231 115.01L383.232 115.685C382.853 117.271 382.131 118.656 381.066 119.84C380.012 121.012 378.663 121.923 377.018 122.574C375.372 123.213 373.49 123.533 371.371 123.533Z" />
							</g>
							<defs>
								<linearGradient id="paint1" x1="79.0005" y1="28.5479" x2="79.0005" y2="123.548" gradientUnits="userSpaceOnUse">
									<stop offset="0" stop-color="#80E893" />
									<stop offset="1" stop-color="#008465" />
								</linearGradient>
								<linearGradient id="paint2" x1="46.1265" y1="120.864" x2="129.501" y2="169" gradientUnits="userSpaceOnUse">
									<stop offset="0" stop-color="#80E893" />
									<stop offset="1" stop-color="#008465" />
								</linearGradient>
							</defs>
						</svg>
					</router-link>
					<button class="btn btn-invisible" @click="toggleSidebar">
						<OwnIcon type="arrow-left-s" v-if="isMenuOpen" aria-label="Fechar menu lateral" />
						<OwnIcon type="arrow-right-s" v-else aria-label="Abrir menu lateral" />
					</button>
				</section>
				<section>
					<p class="menu" v-if="isMenuOpen">Menu</p>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.HOME }" :title="isMenuOpen? '' : 'Geral'">
						<OwnIcon type="calendar-event" />
						<span v-if="isMenuOpen">Geral</span>
					</router-link>
					<router-link class="nav-link" v-if="hasTasksModule" :to="{name: RouteName.ACTIVITIES}" :title="isMenuOpen? '' : 'Atividades'">
						<OwnIcon type="checkbox" />
						<span v-if="isMenuOpen">Atividades</span>
					</router-link>
					<router-link class="nav-link" v-if="hasTasksModule" :to="{name: RouteName.SPRINTS}" :title="isMenuOpen? '' : 'Sprints'">
						<!--<OwnIcon type="box-2" />-->
						<OwnIcon type="memories" />
						<span v-if="isMenuOpen">Sprints</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.MY_REGISTERS }" :title="isMenuOpen? '' : 'Meus registros'">
						<OwnIcon type="contacts-book-2" />
						<span v-if="isMenuOpen">Meus registros</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{name: RouteName.JUSTIFICATIONS}" :title="isMenuOpen? '' : 'Justificativas'">
						<OwnIcon type="quill-pen" />
						<span v-if="isMenuOpen">Justificativas</span>
					</router-link>
					<router-link class="nav-link" :to="{ name: RouteName.BANK }" :title="isMenuOpen? '' : 'Banco de horas'">
						<OwnIcon type="bank" />
						<span v-if="isMenuOpen">Banco de horas</span>
					</router-link>
					<router-link class="nav-link" :to="{ name: RouteName.USERS }" :title="isMenuOpen? '' : 'Usuários'">
						<OwnIcon type="user-3" />
						<span v-if="isMenuOpen">Usuários</span>
					</router-link>
					<router-link class="nav-link" :to="{ name: RouteName.TEAMS }" :title="isMenuOpen? '' : 'Equipes'">
						<OwnIcon type="group-2" />
						<span v-if="isMenuOpen">Equipes</span>
					</router-link>
					<router-link class="nav-link" v-if="hasTasksModule" :to="{name: RouteName.PROJECTS}" :title="isMenuOpen? '' : 'Projetos'">
						<OwnIcon type="folder-2" />
						<span v-if="isMenuOpen">Projetos</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.HOLIDAYS }" :title="isMenuOpen? '' : 'Feriados'">
						<OwnIcon type="goblet" />
						<span v-if="isMenuOpen">Feriados</span>
					</router-link>
				</section>
				<section v-if="isAdmin">
					<p class="menu" v-if="isMenuOpen">Admin</p>
					<router-link class="nav-link" :to="{ name: RouteName.DASHBOARD }" :title="isMenuOpen? '' : 'Dashboard'">
						<OwnIcon type="donut-chart" fill />
						<span v-if="isMenuOpen">Dashboard</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.REGISTERS_ADMIN }" :title="isMenuOpen? '' : 'Registros'">
						<OwnIcon type="contacts-book-2" fill />
						<span v-if="isMenuOpen">Registros</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{name: RouteName.JUSTIFICATIONS_ADMIN}" :title="isMenuOpen? '' : 'Justificativas'">
						<OwnIcon type="quill-pen" fill />
						<span v-if="isMenuOpen">Justificativas</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.ADJUSTMENTS_LIST }" :title="isMenuOpen? '' : 'Ajustes'">
						<OwnIcon type="bar-chart-box" fill />
						<span v-if="isMenuOpen">Ajustes</span>
					</router-link>
					<router-link class="nav-link" :to="{ name: RouteName.BANK_USERS }" :title="isMenuOpen? '' : 'Banco de horas'">
						<OwnIcon type="bank" fill />
						<span v-if="isMenuOpen">Banco de horas</span>
					</router-link>
					<router-link class="nav-link" v-if="hasRegistersModule" :to="{ name: RouteName.ABSENCES }" :title="isMenuOpen? '' : 'Abonos de faltas'">
						<OwnIcon type="file-paper-2" fill />
						<span v-if="isMenuOpen">Abonos de faltas</span>
					</router-link>
					<router-link class="nav-link" :to="{ name: RouteName.SETTINGS }" :title="isMenuOpen? '' : 'Configurações'">
						<OwnIcon type="settings-2" fill />
						<span v-if="isMenuOpen">Configurações</span>
					</router-link>
				</section>
			</nav>
			<aside class="chat-sidebar" :class="{open: isMenuOpen}">
				<div class="user-info-container">
					<img class="img-fluid" :src="imageUrl" alt="Imagem de perfil de User" />
					<div class="user-info" v-if="isMenuOpen">
						<p class="user-name">{{ username }}</p>
						<p class="user-description">{{ description }}</p>
					</div>
					<div class="dropup" v-if="isMenuOpen" ref="dropdown">
						<button type="button" class="btn btn-small btn-invisible" :aria-expanded="isDropdownOpen? 'true' : 'false'" aria-label="Menu do usuário" @click="toggleDropdown">
							<OwnIcon type="more-2" />
						</button>
						<ul class="dropdown-menu" v-show="isDropdownOpen">
							<li>
								<router-link class="dropdown-item" :to="{ name: RouteName.MY_ACCOUNT }">
									<OwnIcon type="account-circle" />
									Minha conta
								</router-link>
							</li>
							<li>
								<!--suppress TypeScriptUnresolvedReference -->
								<button type="button" class="dropdown-item" @click="journeyComponent.openJourneyModal">
									<OwnIcon type="hourglass" />
									Minha jornada
								</button>
							</li>
							<li v-if="hasRegistersModule">
								<!--suppress TypeScriptUnresolvedReference -->
								<button type="button" class="dropdown-item" @click="addAbsenceModal.open">
									<OwnIcon type="file-paper-2" />
									Solicitar abono de falta
								</button>
							</li>
							<li>
								<router-link class="dropdown-item" :to="{ name: RouteName.VERSION_NEWS }">
									<OwnIcon type="fire" />
									Novidades da versão
								</router-link>
							</li>
							<li>
								<hr class="dropdown-divider">
							</li>
							<li>
								<router-link class="dropdown-item" :to="{ name: RouteName.LOGOUT }">
									<OwnIcon type="logout-circle-r" />
									Sair
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</aside>
		</aside>

		<ModalComponent id="addAbsenceModal" ref="addAbsenceModal" block-escape>
			<template #header>
				Solicitar abono de falta
			</template>

			<template #body>
				<div>
					<section>
						<label class="form-label" for="type">
							Tipo
						</label>
						<select id="type" class="form-select" v-model="newAbsence.Type">
							<option hidden disabled selected value="0">Escolha o tipo</option>
							<option v-for="(absenceType, idx) in absenceTypes" :key="idx" :value="absenceType.type">{{ absenceType.text }}</option>
						</select>
					</section>
					<section class="form-flex period">
						<div class="form-item form-content">
							<label class="form-label" for="period-from">
								De
							</label>
							<input class="form-control" id="period-from" type="datetime-local" v-model="newAbsence.BeginDate">
							<label class="form-label" for="period-to">
								a
							</label>
							<input class="form-control" id="period-to" type="datetime-local" v-model="newAbsence.EndDate">
						</div>
					</section>
					<section>
						<label class="form-label" for="message">
							Mensagem
						</label>
						<textarea class="form-control" id="message" autocomplete="off" v-model="newAbsence.Message"></textarea>
					</section>
					<section>
						<label class="form-label" for="attachments">
							Anexos (máx.: 5)
						</label>
						<input class="form-control" id="attachments" type="file" multiple accept="image/png, image/jpeg, .pdf" @change="fileChanged">
					</section>
				</div>
			</template>

			<template #footer>
				<!--suppress TypeScriptUnresolvedReference -->
				<button id="cancel" class="btn btn-secondary" @click="addAbsenceModal.close">
					Cancelar
				</button>
				<button @click="createAbsence" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" :disabled="!canSendAbsence">
					<OwnIcon type="add" />
					Adicionar
				</button>
			</template>
		</ModalComponent>

		<JourneyComponent ref="journeyComponent" />
	</div>
</template>

<script setup lang="ts">

import { computed, onMounted, ref, watch } from "vue";
import RouteName from "@/router/routes-name";
import { useStore } from "vuex";
import { AuthGetter } from "@/store/modules/auth/getters";
import { useRoute } from "vue-router";
import { Absence, absenceTypes } from "@/models/Admin/Absence";
import { loadStart, loadStop } from "@/utils/loading";
import { notify } from "@kyvg/vue3-notification";
import { DefaultError, DefaultWarn, getImageURL, NotificationType } from "@/utils/general";
import CreateAbsenceSelfRequest from "@/api/requests/absenses/CreateAbsenceSelfRequest";
import HTMLInputEvent from "@/models/HTMLInputEvent";
import { AbsenceType } from "@/api/requests/absenses/GetAbsencesRequest";
import OwnIcon from "@/components/OwnIcon.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import JourneyComponent from "@/components/JourneyComponent.vue";
import { useClickOutside } from "@/utils/onClickOutside";
import { Dropdown } from "bootstrap";

const store = useStore();
const isAdmin = computed(() => store.getters[AuthGetter.IS_ADMIN]);
const username = computed(() => store.getters[AuthGetter.USERNAME]);
const imageId = computed(() => store.getters[AuthGetter.IMAGE_ID]);
const imageUrl = computed(() => imageId.value !== null ? getImageURL(imageId.value) : "");
const hasTasksModule = computed(() => store.getters[AuthGetter.HAS_TASKS_MODULE]);
const hasRegistersModule = computed(() => store.getters[AuthGetter.HAS_REGISTERS_MODULE]);

const description = isAdmin.value ? "Administrador" : "Colaborador";

const dropdown = ref<InstanceType<typeof Dropdown> | null>(null);

// Variaveis do modal
const journeyComponent = ref<InstanceType<typeof JourneyComponent>>();
const addAbsenceModal = ref<InstanceType<typeof ModalComponent>>();

const route = useRoute();

// Observar o caminho atual da rota, quando for alterado emitir o evento 'close-sidebar'
// fechando o sibebar depois do usuário escolher uma rota
watch(() => route.fullPath, () => {
	isDropdownOpen.value = false;
	if (window.innerWidth <= 768 && isMenuOpen.value) {
		toggleSidebar();
	}
});

useClickOutside(dropdown, () => {
	isDropdownOpen.value = false;
})

const isMenuOpen = ref(window.innerWidth > 768);
const toggleSidebar = () => {
	isMenuOpen.value = !isMenuOpen.value;
	localStorage.setItem("isSidebarOpen", String(isMenuOpen.value));
};

const isDropdownOpen = ref(false);
const toggleDropdown = () => {
	isDropdownOpen.value = !isDropdownOpen.value;
};

const newAbsence = ref<Absence>(new Absence());

const canSendAbsence = computed(() => {
	return newAbsence.value.Message.length > 0 &&
		newAbsence.value.Type != 0 as AbsenceType &&
		newAbsence.value.BeginDate != "" &&
		newAbsence.value.EndDate != "";
});

/**
 * Solicita à API a criação de uma solicitação de ausência
 */
const createAbsence = async () => {
	try {
		loadStart();
		const request = new CreateAbsenceSelfRequest();
		request.Type = newAbsence.value.Type;
		request.BeginDate = newAbsence.value.BeginDate;
		request.EndDate = newAbsence.value.EndDate;
		request.Message = newAbsence.value.Message;
		request.File = newAbsence.value.Files;
		let response = await request.execute();

		if (!response.Success) {
			notify(DefaultError(response.Errors[0].Message));
			console.error(response.Errors[0].Message);
		}
		else {
			addAbsenceModal.value.close();
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: "Solicitação de ausência justificada adicionada.",
			});
			newAbsence.value = new Absence();
		}
	}
	catch (err) {
		notify(DefaultWarn);
		console.warn(err);
	}

	finally {
		loadStop();
	}
};

const fileChanged = (e: HTMLInputEvent) => {
	if (e.target.files === null)
		return;

	if (e.target.files.length === 0) {
		newAbsence.value.Files = null;
		return;
	}

	for (let file of e.target.files) {
		newAbsence.value.Files?.push(file);
		console.log(file);
	}
};


onMounted(() => {
	if (localStorage.getItem("isSidebarOpen"))
		isMenuOpen.value = localStorage.getItem("isSidebarOpen") == "true";
	else
		localStorage.setItem("isSidebarOpen", String(isMenuOpen.value));
});

</script>

<style lang="scss">
@import "@/styles/Variables.scss";

#sidebar-container {
	overflow-y: auto;
	scrollbar-gutter: stable;

	.burger-sidebar {
		display: none;
		bottom: 2rem;
		right: 1rem;
		position: absolute;
		border-radius: 100%;
		padding: 5px;
		width: 4rem;
		z-index: 2;
		height: 4rem;
		//background-color: $ot-highlight-title;
		color: $ot-dark-text-primary;

		@media(max-width: 768px) {
			display: block;
		}
	}

	&:has(.open) {
		min-width: 18rem;

		@media(max-width: 768px) {
			min-width: 100dvw;
		}
	}

	@media(min-width: 768px) {
		min-width: 6rem;
	}
}

aside#sidebar {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: opacity 0.5s ease-in-out;
	background-color: $ot-background-secondary-full;
	background-origin: content-box;


	&.open {
		//min-width: 17rem;

		@media(max-width: 768px) {
			min-width: 100dvw;
		}

		.nav {
			gap: 1rem;

			.nav-link {
				text-align: left;
				font-size: 1rem;
			}
		}
	}

	.btn-small {
		border-radius: 100%;
		padding: 0;
		width: 2.5rem;
		height: 2.5rem;
	}

	.nav {
		flex-direction: column;
		align-items: flex-start;
		flex-wrap: nowrap !important;
		gap: 2rem;

		.img-fluid {
			padding: 0.5rem;
			width: 85%;
			object-fit: cover;
		}

		section {
			width: 100%;
		}

		> :first-child {
			padding-left: 1rem;
			margin-top: 0.5rem;
		}

		a {
			padding: 0.75rem 1.5rem;
		}

		p {
			padding-left: 1.5rem;
		}

		.navbar-header {
			display: grid;
			grid-auto-flow: column;
			align-items: center;

			* {
				transition: all ease-in-out 0.3s;
			}

			a {
				padding: 0;
			}
		}

		.menu {
			margin-bottom: 0;
			padding-bottom: 0;
			font-size: small;
			color: $ot-text-secondary;
		}

		.nav-link {
			font-weight: 600;
			font-size: 1.2rem;
			color: $ot-text-primary;
			width: 100%;
			text-align: center;
			border-right: solid 3px transparent;
			transition: color ease-in-out 0.3s;

			&:hover {
				color: $ot-highlight-title;
			}

			&.router-link-exact-active {
				color: $ot-highlight-active;
				border-color: $ot-highlight-active;
			}

			span {
				margin-left: 0.3rem
			}

		}
	}

	.chat-sidebar {
		&.open {
			min-width: 15.5rem;

			.user-info,
			.img-fluid {
				margin-right: 0.7rem;
			}

			.user-info-container {
				justify-content: start;
			}
		}

		padding: 1rem;

		.img-fluid {
			width: 2.5rem;
			height: 2.5rem;
			aspect-ratio: 1;
			border-radius: 100%;
		}

		.user-info-container {
			display: flex;
			justify-content: center;

			.user-name,
			.user-description {
				margin: 0;
				text-align: left;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 8rem;
			}

			.user-name {
				font-weight: 600;
				font-size: 16px;
			}

			.user-description {
				font-weight: normal;
				font-size: 12px;
			}

			.dropup {
				margin-left: auto;
			}

			.dropdown-menu {
				bottom: 2.5rem;
				right: 0;
				display: unset;
			}
		}
	}

	.table-inputs {
		td {
			margin-right: 10px;
		}
	}

	.nav {
		.btn-close {
			display: none;
		}
	}

	@media (max-width: 768px) {
		position: sticky;
		z-index: 5;
		width: 100%;

		&:not(.open) {
			display: none;
		}

		.nav {
			max-width: unset;

			.btn-close {
				display: block;
				padding: 0.5rem 0.5rem;
				margin-left: auto;
				margin-right: 1rem;
			}
		}
	}
}

body:has(#app > main.dark) aside#sidebar {
	.chat-sidebar .user-info-container .dropdown-menu {
		background-color: $ot-dark-background-primary;
	}
}
</style>
