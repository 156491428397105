<template>
	<main id="search">
		<!--<div class="search-bar" v-if="routeTitle === RouteName.ACTIVITIES">-->
		<!--	<input class="form-control" type="search" id="search-bar" placeholder="Procurar atividade...">-->
		<!--	<OwnIcon type="search" />-->
		<!--</div>-->
		<button class="btn btn-small btn-secondary" @click="changeTheme" aria-label="Mudar tema claro/escuro">
			<OwnIcon type="sun" v-if="isDarkMode" />
			<OwnIcon type="moon" v-else />
		</button>
		<NotificationContainer />
	</main>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { AuthGetter } from "@/store/modules/auth/getters";
import { AuthMutation } from "@/store/modules/auth/mutations";
import NotificationContainer from "@/components/NotificationComponent.vue";
import OwnIcon from "@/components/OwnIcon.vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

// const routeTitle = computed(() => router.currentRoute.value.name);

const isDarkMode = computed(() => store.getters[AuthGetter.IS_DARK_MODE]);

/**
 * faz a troca do tema tanto claro para o escuro e vice-versa
 */
const changeTheme = () => {
	store.commit(AuthMutation.CHANGE_THEME);
};

</script>

<style lang="scss">
main#search {
	display: grid;
	gap: 0.5rem;
	grid-auto-flow: column;
	

	.btn-small {
		border-radius: 100%;
		padding: 0;
		width: 2.5rem;
		height: 2.5rem;

		i {
			margin: 0
		}
	}
}
</style>