import { GetterTree } from "vuex";
import { IRootState } from "@/store";
import { IUpdateState } from "@/store/modules/updates/state";
export enum UpdateGetter {
	UPDATE_LIST = "UPDATE/UPDATE_LIST",
	UPDATED = "UPDATE/IS_UPDATED",
}
export const getters: GetterTree<IUpdateState, IRootState> = {
	[UpdateGetter.UPDATE_LIST](state: IUpdateState) {
		return state.newsArray;
	},

	[UpdateGetter.UPDATED](state: IUpdateState) {
		return state.oldVersion !== state.newsArray[0].version;
	},
};

