import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import account from "./routes/account";
import general from "./routes/general";
import hours from "./routes/hours";
import admin from "./routes/admin";
import management from "./routes/management";
import projects from "./routes/project";
import justifications from "./routes/justifications";
import { loadStart } from "@/utils/loading";
import { AuthGetter } from "@/store/modules/auth/getters";
import { AuthAction } from "@/store/modules/auth/actions";
import RouteName from "@/router/routes-name";


const routes: Array<RouteRecordRaw> = [
	...account,
	...general,
	...hours,
	...management,
	...admin,
	...projects,
	...justifications,

	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		meta: {
			title: "Página não encontrada",
		},
		component: () => import(/* webpackChunkName: "not-found" */ "@/views/General/NotFound.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

let firstLoad = true;

router.beforeEach(async (to, from, next) => {
	if (firstLoad) {
		try {
			await store.dispatch(AuthAction.LOAD_USER_INFO);
		}
		catch (err) {
			// ignore
		}
		finally {
			firstLoad = false;
		}
	}

	if (typeof to.meta.allowAnonymous === "undefined" || to.meta.allowAnonymous === false) {
		if (store.getters[AuthGetter.IS_LOGGED_IN] === false) {
			next({name: "login"});
			return;
		}
	}

	if (to.meta.adminOnly && !store.getters[AuthGetter.IS_ADMIN]) {
		next({name: "home"});
		return;
	}

	if (to.name === RouteName.LOGIN && store.getters[AuthGetter.IS_LOGGED_IN]) {
		next(from);
		return;
	}

	// Caso não tiver nenhum módulo, carregar a tela de Configurações quando admin, se não a tela da Minha conta
	if (store.getters[AuthGetter.IS_LOGGED_IN] && !store.getters[AuthGetter.HAS_REGISTERS_MODULE] && !store.getters[AuthGetter.HAS_TASKS_MODULE]) {
		if (store.getters[AuthGetter.IS_ADMIN])
			next({name: RouteName.SETTINGS});
		else
			next({name: RouteName.MY_ACCOUNT});
		return;
	}
	
	if (to.meta.registersModuleOnly && !store.getters[AuthGetter.HAS_REGISTERS_MODULE]) {
		next({name: RouteName.ACTIVITIES});
		return;
	}
	
	if (to.meta.tasksModuleOnly && !store.getters[AuthGetter.HAS_TASKS_MODULE]) {
		next({name: RouteName.HOME});
		return;
	}

	if (from.path !== to.path)
		loadStart(); // Exibir o loading

	// Definir o título da página de acordo com a rota
	if (typeof to.meta.title !== "undefined") {
		document.title = to.meta.title + " - OwnTime";
	}
	else {
		document.title = "OwnTime";
	}

	next();
});


export default router;
