import { onBeforeUnmount, onMounted } from "vue";

/**
 * @param el_target_ref elemento raiz pelo qual o clique fora é o gatilho para callback_fn
 * @param callback_fn função chamada quando o usuário clica fora
 * @returns
 */
export const useClickOutside = (el_target_ref: any, callback_fn: any) => {
	if (!el_target_ref) return;
	const listener = (e: any) => {
		if (e.target === el_target_ref.value || e.composedPath().includes(el_target_ref.value)) {
			return
		}
		if (typeof callback_fn === 'function') {
			callback_fn();
		}
	}
	
	onMounted(() => {
		window.addEventListener("click", listener);
	})

	onBeforeUnmount(() => {
		window.removeEventListener("click", listener);
	})
	return {
		listener
	}
};

