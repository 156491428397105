enum RouteName {
	// Account
	LOGIN = "login",
	LOGOUT = "logout",
	MY_ACCOUNT = "my-account",
	NEW_PASSWORD = "new-password",
	
	// General
	HOME = "home",
	VERSION_NEWS = "version-news",
	
	// Atividades
	ACTIVITIES = "activities",
	
	// Sprints
	SPRINTS = "sprints",
	
	// Projetos
	PROJECTS = "projects",
	CREATE_PROJECT = "create-project",
	EDIT_PROJECT = "edit-project",
	VISUALIZE_PROJECT = "visualize-project",

	// Hours
	MY_REGISTERS = "my-registers",
	BANK = "bank",
	ADJUSTMENTS = "adjustments",
	NEW_REGISTERS = "new-registers",
	
	// Justifications
	JUSTIFICATIONS = "justifications",

	// Users
	USERS = "users",
	VISUALIZE_USER = "visualize-user",

	// Teams
	TEAMS = "teams",
	VISUALIZE_TEAM = "visualize-team",

	// Holidays
	HOLIDAYS = "holidays",
	
	// ADMIN
	// -- Dashboard
	DASHBOARD = "dashboard",
	VISUAL_MANAGEMENT = "visual-management",
	
	// -- Registers
	REGISTERS_ADMIN = "registers-admin",
	APPRAISE_REGISTERS = "appraise-registers",

	// -- Justifications
	JUSTIFICATIONS_ADMIN = "justifications-admin",
	
	// -- Teams
	CREATE_TEAM = "create-team",
	EDIT_TEAM = "edit-team",

	// -- Users
	CREATE_USER = "create-user",
	EDIT_USER = "edit-user",
	
	// -- Adjustments
	ADJUSTMENTS_LIST = "adjustments-list",
	APPRAISE_ADJUSTMENTS = "appraise-adjustments",
	
	// -- Bank
	BANK_USERS = "bank-users",
	
	// -- Absenses
	ABSENCES = "absenses",
	VISUALIZE_ABSENCE = "visualize-absence",
	
	// -- Settings
	SETTINGS = "settings",
}

export default RouteName;