import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/projetos",
		name: RouteName.PROJECTS,
		meta: {
			tasksModuleOnly: true,
			title: "Projetos",
		},
		component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/ProjectsComponent.vue"),
	},
	{
		path: "/projetos/criar",
		name: RouteName.CREATE_PROJECT,
		meta: {
			tasksModuleOnly: true,
			adminOnly: true,
			title: "Criar projeto",
		},
		component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/CreateProject.vue"),
	},
	{
		path: "/projetos/editar/:projectId",
		name: RouteName.EDIT_PROJECT,
		meta: {
			tasksModuleOnly: true,
			adminOnly: true,
			title: "Editar projeto",
		},
		props: true,
		component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/EditProject.vue"),
	},
	{
		path: "/projetos/:projectId",
		name: RouteName.VISUALIZE_PROJECT,
		meta: {
			tasksModuleOnly: true,
			title: "Visualizar projeto",
		},
		props: true,
		component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects/VisualizeProject.vue"),
	},
];

export default routes;